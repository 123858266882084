import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class SignaturesService {
  url = `${environment.BASE_URL}/events`

  private http = inject(HttpClient)

  getSignatures(): Observable<any> {
    return this.http.get(`${this.url}/list`)
  }

  getSignatureDetail(id: any): Observable<any> {
    return this.http.get(`${this.url}/${id}/read`)
  }

  updateProperty(payload: any, id: any): Observable<any> {
    const queryParams =
      `property_id=${encodeURIComponent(payload.property_id)}&` +
      `user_id=${encodeURIComponent(payload.user_id)}&` +
      `property_name=${encodeURIComponent(payload.property_name)}&` +
      `property_description=${encodeURIComponent(payload.property_description)}`

    const fullUrl = `${this.url}/${payload.property_id}/update?${queryParams}`
    return this.http.put(fullUrl, {})
  }

  deleteSignature(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}/delete`)
  }

  createSignature(query: any, payload: any): Observable<any> {
    const queryParams =
      `user_id=${encodeURIComponent(query.user_id)}&` +
      `event_start_date=${encodeURIComponent(query.event_start_date)}&` +
      `event_end_date=${encodeURIComponent(query.event_end_date)}&` +
      `event_label_id=${encodeURIComponent(query.event_label_id)}`

    const fullUrl = `${this.url}/create?${queryParams}`
    return this.http.post(fullUrl, payload)
  }
}
