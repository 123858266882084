import { CommonModule } from '@angular/common'
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  inject,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { TabMenuModule } from 'primeng/tabmenu'
import { ConfirmationService, SortMeta } from 'primeng/api'
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { MultiSelectModule } from 'primeng/multiselect'
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms'
import { DropdownModule } from 'primeng/dropdown'
import { ToastModule } from 'primeng/toast'
import { ArraySpacingPipe } from '../../shared/pipes/array-spacing.pipe'

@Component({
  selector: 'app-properties-fill',
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    ReactiveFormsModule,
    DropdownModule,
    ToastModule,
    ArraySpacingPipe,
  ],
  providers: [ConfirmationService],
  template: `
    <p-toast></p-toast>
    <div class="pt-4 px-4 grid">
      <p-table
        #dt
        [columns]="selectedColumns"
        [value]="data"
        dataKey="PROPERTY_ID"
        [tableStyle]="{ 'min-width': '50rem' }"
        [rows]="50"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        sortMode="multiple"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
        [scrollable]="true"
        [loading]="loading"
        [multiSortMeta]="multiSortMeta"
        scrollHeight="53vh">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              class="table-header text-small-strong"
              *ngFor="let col of columns"
              pSortableColumn="{{ col.field }}">
              <div style="display: flex; align-items: center">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                  class="mr-auto"></p-sortIcon>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-expanded="expanded">
          <tr class="text-small-roman">
            <td>{{ rowData.PROPERTY_NAME }}</td>
            <td>{{ rowData.PROPERTY_DESCRIPTION }}</td>
            <td>{{ rowData.PROPERTY_DATA_OPERAND }}</td>
            <td>{{ rowData.PROPERTY_DATA_VALUES | arraySpacing }}</td>
            <td style="width: 20rem">
              <ng-container [ngSwitch]="rowData.PROPERTY_DATA_OPERAND">
                <ng-container *ngSwitchCase="'ANNOTATION'">
                  <input
                    type="text"
                    pInputText
                    [placeholder]="
                      getPlaceholder(rowData.PROPERTY_DATA_OPERAND)
                    "
                    [(ngModel)]="rowData.PROPERTY_DATA_VALUES_FILLED"
                    (ngModelChange)="checkAllPropertiesFilled()" />
                </ng-container>
                <ng-container *ngSwitchCase="'BOOLEAN'">
                  <p-dropdown
                    appendTo="body"
                    [options]="booleanOptions"
                    [placeholder]="
                      getPlaceholder(rowData.PROPERTY_DATA_OPERAND)
                    "
                    [(ngModel)]="rowData.PROPERTY_DATA_VALUES_FILLED"
                    (ngModelChange)="checkAllPropertiesFilled()">
                  </p-dropdown>
                </ng-container>
                <ng-container *ngSwitchCase="'CHOICE'">
                  <p-dropdown
                    styleClass="w-full"
                    appendTo="body"
                    [placeholder]="
                      getPlaceholder(rowData.PROPERTY_DATA_OPERAND)
                    "
                    [options]="rowData.PROPERTY_DATA_VALUES"
                    [(ngModel)]="rowData.PROPERTY_DATA_VALUES_FILLED"
                    (ngModelChange)="checkAllPropertiesFilled()">
                  </p-dropdown>
                </ng-container>
                <ng-container *ngSwitchCase="'RANGE'">
                  <div class="flex flex-col gap-4">
                    <input
                      type="number"
                      pInputText
                      [placeholder]="
                        getPlaceholder(rowData.PROPERTY_DATA_OPERAND)
                      "
                      [(ngModel)]="rowData.PROPERTY_DATA_VALUES_FILLED"
                      (ngModelChange)="validateRange(rowData)" />
                    <small
                      class="text-red-500 error-message"
                      [hidden]="!rowData.validationError">
                      {{ rowData.validationError }}
                    </small>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'MAXIMUM'">
                  <div class="flex flex-col gap-4">
                    <input
                      type="number"
                      pInputText
                      [placeholder]="
                        getPlaceholder(rowData.PROPERTY_DATA_OPERAND)
                      "
                      [(ngModel)]="rowData.PROPERTY_DATA_VALUES_FILLED"
                      (ngModelChange)="validateRange(rowData)" />
                    <small
                      class="text-red-500 error-message"
                      [hidden]="!rowData.validationError">
                      {{ rowData.validationError }}
                    </small>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'MINIMUM'">
                  <div class="flex flex-col gap-4">
                    <input
                      type="number"
                      pInputText
                      [placeholder]="
                        getPlaceholder(rowData.PROPERTY_DATA_OPERAND)
                      "
                      [(ngModel)]="rowData.PROPERTY_DATA_VALUES_FILLED"
                      (ngModelChange)="validateRange(rowData)" />
                    <small
                      class="text-red-500 error-message"
                      [hidden]="!rowData.validationError">
                      {{ rowData.validationError }}
                    </small>
                  </div>
                </ng-container>
              </ng-container>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td style="border: none;" colspan="5">
              No results for your search terms.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [
    `
      .table-header {
        background-color: #cf9bff40;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
      }

      .text-small-strong {
        color: #8a00e5 !important;
      }

      td {
        border-right: 1px solid #969696;
      }

      .table-header-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }

      .error-message {
        min-height: 2rem;
        display: block;
      }
    `,
  ],
})
export class PropertiesFillComponent implements OnInit, OnChanges {
  @Input() data!: any
  @Output() allPropertiesFilled = new EventEmitter<boolean>()
  multiSortMeta: SortMeta[] = [
    { field: 'PROPERTY_LAST_UPDATED_DATETIME', order: -1 },
  ]
  selectedProperty: any = null
  deleteReason!: string
  currentUserId = localStorage.getItem('userId')
  dynamicOptions: any[] = []
  helperMessage: string = ''
  visible: boolean = false
  propertyForm!: FormGroup
  loading: boolean = false
  filledProperties: any[] = []
  @ViewChild('confirmDialog') confirmDialog!: any

  types = [
    { name: 'Annotation', code: 'ANNOTATION' },
    { name: 'Choice', code: 'CHOICE' },
    { name: 'Range', code: 'RANGE' },
    { name: 'Minimum', code: 'MINIMUM' },
    { name: 'Maximum', code: 'MAXIMUM' },
    { name: 'Boolean', code: 'BOOLEAN' },
  ]

  booleanOptions = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ]

  cols: any[] = [
    { field: 'PROPERTY_NAME', header: 'Name', align: 'default', type: 'text' },
    {
      field: 'PROPERTY_DESCRIPTION',
      header: 'Description',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_DATA_OPERAND',
      header: 'Type',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_DATA_VALUES',
      header: 'Options',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_DATA_VALUES_FILLED',
      header: 'Values',
      align: 'default',
      type: 'text',
    },
  ]

  selectedColumns = this.cols

  private fb = inject(FormBuilder)

  ngOnInit(): void {
    // this.propertyForm = this.fb.group({
    //   name: new FormControl({ value: '', disabled: true }, Validators.required),
    //   description: new FormControl(
    //     { value: '', disabled: true },
    //     Validators.required
    //   ),
    //   type: new FormControl({ value: '', disabled: true }, Validators.required),
    //   options: this.fb.array([]),
    //   value: new FormControl(''),
    //   id: new FormControl(''),
    // })
    // this.propertyForm.get('type')?.valueChanges.subscribe((type) => {
    //   this.updateValidatorsBasedOnType(type)
    // })
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      if (this.data?.length) {
        this.checkAllPropertiesFilled()
      }
    }
  }

  isEditableType(type: string): boolean {
    return ['CHOICE', 'RANGE', 'MINIMUM', 'MAXIMUM'].includes(type)
  }

  getPlaceholder(type: string): string {
    switch (type) {
      case 'CHOICE':
        return 'Enter CHOICE value'
      case 'RANGE':
        return 'Enter RANGE value'
      case 'MINIMUM':
        return 'Enter MINIMUM value'
      case 'MAXIMUM':
        return 'Enter MAXIMUM value'
      default:
        return ''
    }
  }

  // updateValidatorsBasedOnType(type: string) {
  //   this.options.clear()
  //   const defaultControl = this.propertyForm.get('value')

  //   if (!defaultControl) return

  //   this.helperMessage = ''

  //   switch (type) {
  //     case 'ANNOTATION':
  //       defaultControl.clearValidators()
  //       defaultControl.setValue('')
  //       this.helperMessage = 'Annotations cannot have predefined values.'
  //       break
  //     case 'CHOICE':
  //       defaultControl.clearValidators()
  //       defaultControl.setValue('')
  //       this.helperMessage = 'Choice requires at least two list elements.'
  //       this.addOptionControls(2)
  //       break
  //     case 'RANGE':
  //       defaultControl.clearValidators()
  //       defaultControl.setValue('')
  //       this.helperMessage =
  //         'Range requires a list of two elements (lower and upper bound).'
  //       this.addOptionControls(2)
  //       break
  //     case 'MINIMUM':
  //     case 'MAXIMUM':
  //       defaultControl.clearValidators()
  //       defaultControl.setValue('')
  //       this.helperMessage = `${
  //         type.charAt(0).toUpperCase() + type.slice(1)
  //       } requires a list of only one element.`
  //       this.addOptionControls(1)
  //       break
  //     case 'boolean':
  //       defaultControl.clearValidators()
  //       defaultControl.setValue('')
  //       this.helperMessage =
  //         'There is no need to provide values for boolean operand.'
  //       break
  //     default:
  //       defaultControl.clearValidators()
  //       defaultControl.setValue('')
  //       break
  //   }

  //   defaultControl.updateValueAndValidity()
  // }

  isPropertyFilled(propertyId: string): boolean {
    return this.filledProperties.some((prop) => prop.property_id === propertyId)
  }

  get options(): FormArray {
    return this.propertyForm.get('options') as FormArray
  }

  addOptionControl() {
    this.options.push(this.fb.control('', Validators.required))
  }

  addOptionControls(count: number) {
    for (let i = 0; i < count; i++) {
      this.options.push(this.fb.control('', Validators.required))
    }
  }

  removeOptionControl(index: number) {
    this.options.removeAt(index)
  }

  validateRange(rowData: any) {
    const values = rowData.PROPERTY_DATA_VALUES
    const operand = rowData.PROPERTY_DATA_OPERAND
    let errorMessage = ''
    const enteredValue = rowData.PROPERTY_DATA_VALUES_FILLED

    if (operand === 'MINIMUM' && values.length === 1) {
      const minValue = values[0]
      if (enteredValue < minValue) {
        errorMessage = `Value must be greater than or equal to ${minValue}`
      }
    } else if (operand === 'MAXIMUM' && values.length === 1) {
      const maxValue = values[0]
      if (enteredValue > maxValue) {
        errorMessage = `Value must be less than or equal to ${maxValue}`
      }
    } else if (operand === 'RANGE' && values.length === 2) {
      const [min, max] = values
      if (enteredValue < min || enteredValue > max) {
        errorMessage = `Value must be between ${min} and ${max}`
      }
    } else {
      errorMessage = 'Invalid PROPERTY_DATA_OPERAND or PROPERTY_DATA_VALUES'
    }

    rowData.validationError = errorMessage

    this.checkAllPropertiesFilled()
  }

  checkAllPropertiesFilled() {
    const allFilled = this.data.every((property: any) => {
      const filledValue = property.PROPERTY_DATA_VALUES_FILLED
      const hasNoValidationError =
        !property.validationError || property.validationError === ''

      if (!hasNoValidationError) {
        return false // If there's a validation error, the property is not considered filled
      }

      switch (property.PROPERTY_DATA_OPERAND) {
        case 'BOOLEAN':
          return filledValue !== null && filledValue !== undefined
        case 'ANNOTATION':
          return true
        case 'CHOICE':
        case 'RANGE':
        case 'MINIMUM':
        case 'MAXIMUM':
          return filledValue !== null && filledValue !== undefined
        default:
          return true
      }
    })

    this.allPropertiesFilled.emit(allFilled)
  }
}
