import { CommonModule } from '@angular/common'
import { Component, OnInit, ViewChild, inject } from '@angular/core'
import { TabMenuModule } from 'primeng/tabmenu'
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { SortMeta } from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect'
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { DialogModule } from 'primeng/dialog'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { PropertiesSelectorComponent } from '../properties/properties-selection.component'
import { StepsModule } from 'primeng/steps'
import { MachinesSelectorComponent } from './machine-selection.component'
import { CalendarModule } from 'primeng/calendar'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { SignaturesService } from '../../shared/services/signatures.service'
import { StarmsService } from '../../shared/services/starms.service'
import { finalize } from 'rxjs'
import { LabelsService } from '../../shared/services/labels.service'
import { TimeSeriesChart } from '../../shared/components/timeseries-chart/timeseries-chart.component'
import { TagsSelectorComponent } from './tags-selection.component'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { ObjectUtils } from 'primeng/utils'
import { LabelsSelectionComponent } from '../labels/labels-selection.component'
import { PropertyFormComponent } from '../properties/properties-form.component'
import { PropertiesFillComponent } from '../properties/properties-fill.component'
import { SignatureDetailComponent } from './signature-detail.component'
import { CustomLocalDatePipe } from '../../shared/pipes/custom-date-time.pipe'

@Component({
  selector: 'app-signatures',
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ConfirmDialogModule,
    DropdownModule,
    PropertiesSelectorComponent,
    StepsModule,
    MachinesSelectorComponent,
    CalendarModule,
    AutoCompleteModule,
    TimeSeriesChart,
    TagsSelectorComponent,
    ProgressSpinnerModule,
    LabelsSelectionComponent,
    PropertyFormComponent,
    PropertiesFillComponent,
    SignatureDetailComponent,
    CustomLocalDatePipe,
  ],
  providers: [ConfirmationService],
  template: `
    <div class="pt-4 px-4 grid">
      <p-button
        label="New signature"
        (onClick)="showDialog()"
        class="rounded-lg pb-4"
        icon="pi pi-plus"></p-button>

      <p-table
        #dt
        [columns]="selectedColumns"
        [loading]="isLoadingSignatures"
        [value]="data"
        dataKey="EVENT_TRX_ID"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="50"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        sortMode="multiple"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
        [scrollable]="true"
        [multiSortMeta]="multiSortMeta"
        scrollHeight="53vh">
        <ng-template pTemplate="caption">
          <div class="table-header-container" style="padding-bottom: 1rem">
            <h2>List of Signatures</h2>
            <p-multiSelect
              [options]="cols"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ 'min-width': '200px' }"
              placeholder="Choose Columns"></p-multiSelect>
            <div style="display: flex; gap: 1.5rem;">
              <p-button
                class="secondary-btn"
                [rounded]="true"
                (click)="resetSort()"
                label="Reset sorting"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.13843 6.75101L8.19576 5.80768L11.3338 2.66968L14.4718 5.80768L13.5291 6.75101L12.0004 5.22234V12.667H10.6671V5.22234L9.13843 6.75101Z"
                      fill="#8A00E5" />
                    <path
                      d="M3.99996 10.7779V3.33328H5.33396V10.7779L6.86196 9.24928L7.8053 10.1919L4.6673 13.3299L1.5293 10.1919L2.47196 9.24928L3.99996 10.7779Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
              <p-button
                class="secondary-btn"
                [rounded]="true"
                label="Reset filtering"
                (click)="dt.reset()"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.31998 9.33333L1.33398 2H14.6673L8.66732 9.33333V14H7.33398V9.33333H7.31998ZM11.854 3.33333H4.14398L7.95332 8H8.03598L11.854 3.33333Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
              <p-button
                class="secondary-btn"
                [rounded]="true"
                label="Download csv"
                (click)="dt.exportCSV()"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.5727 6.15333L12.426 7.18L7.99935 10.8667L3.57268 7.18L4.42602 6.15333L7.33268 8.58V2H8.66602V8.58L11.5727 6.15333Z"
                      fill="#8A00E5" />
                    <path
                      d="M13.3327 14V12.6667H2.66602V14H13.3327Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3rem">Actions</th>
            <th
              class="table-header text-small-strong"
              *ngFor="let col of columns"
              pSortableColumn="{{ col.field }}">
              <div style="display: flex; align-items: center">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                  class="mr-auto"></p-sortIcon>
              </div>
            </th>
          </tr>
          <tr>
            <th></th>
            <th style="min-width: 14vw;" *ngFor="let col of cols">
              <p-columnFilter
                [type]="col.type"
                matchMode="contains"
                [field]="col.field"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-expanded="expanded">
          <tr class="text-small-roman">
            <td>
              <div
                class="flex gap-4"
                style="padding: 2rem 0rem;
    margin: 0rem 1rem;">
                <p-button
                  icon="pi pi-eye"
                  size="small"
                  [rounded]="true"
                  [text]="true"
                  (onClick)="showSignatureDetail(rowData)"
                  severity="help"
                  [outlined]="true"></p-button>
                <p-button
                  icon="pi pi-trash"
                  size="small"
                  [rounded]="true"
                  [text]="true"
                  (onClick)="confirm(rowData)"
                  severity="danger"
                  [outlined]="true"></p-button>
              </div>
            </td>
            <td *ngFor="let col of columns">
              <span *ngIf="col.field === 'properties'" [pRowToggler]="rowData">
                Click to expand
              </span>
              <span
                *ngIf="
                  col.field === 'EVENT_LAST_UPDATED_DATETIME' ||
                  col.field === 'EVENT_CREATED_DATETIME' ||
                  col.field === 'EVENT_START_DATE' ||
                  col.field === 'EVENT_END_DATE'
                ">
                {{ rowData[col.field] | customLocalDate : 'medium' }}
              </span>
              <span *ngIf="col.field === 'tags'">
                <ul>
                  <li *ngFor="let tag of rowData.ASSET?.tags">
                    {{ tag.tagAlias }} (ID: {{ tag.tagId }})
                  </li>
                </ul>
              </span>
              <span
                *ngIf="
                  ![
                    'actions',
                    'properties',
                    'EVENT_LAST_UPDATED_DATETIME',
                    'EVENT_CREATED_DATETIME',
                    'EVENT_START_DATE',
                    'EVENT_END_DATE',
                    'EVENT_CREATED_USER_ID',
                    'tags'
                  ].includes(col.field)
                ">
                {{ resolveFieldData(rowData, col.field) }}
              </span>
              <span *ngIf="col.field === 'EVENT_CREATED_USER_ID'">
                {{ rowData['EVENT_LAST_UPDATED_USER_LAST_NAME'] }},
                {{ rowData['EVENT_LAST_UPDATED_USER_GIVEN_NAME'] }}
                ( {{ rowData[col.field] }} )
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-product>
          <tr>
            <td colspan="7">
              <div class="p-3">
                <p-table [value]="product.properties" dataKey="name">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="name">
                        Name <p-sortIcon field="price"></p-sortIcon>
                      </th>
                      <th pSortableColumn="description">
                        Description <p-sortIcon field="customer"></p-sortIcon>
                      </th>
                      <th pSortableColumn="type">
                        Type <p-sortIcon field="date"></p-sortIcon>
                      </th>
                      <th pSortableColumn="options">
                        Options <p-sortIcon field="amount"></p-sortIcon>
                      </th>
                      <th pSortableColumn="default">
                        Default <p-sortIcon field="status"></p-sortIcon>
                      </th>
                      <th style="width: 4rem"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-property>
                    <tr>
                      <td>{{ property.name }}</td>
                      <td>{{ property.description }}</td>
                      <td>{{ property.type }}</td>
                      <td>{{ property.options }}</td>
                      <td>{{ property.default }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <app-signature-detail
      *ngIf="showSignatureDetailDialog"
      [visible]="showSignatureDetailDialog"
      [signatures]="data"
      [currentIndex]="selectedSignatureIndex"
      (onHide)="handleSignatureDialogOnHide()">
    </app-signature-detail>

    <p-dialog
      header="Header"
      [(visible)]="visible"
      (onHide)="handleDialogOnHide()"
      [modal]="true"
      [style]="{ height: '90vh', width: '85vw' }"
      [breakpoints]="{ '1199px': '85vw', '575px': '90vw' }">
      <ng-template pTemplate="header">
        <div
          class="inline-flex align-items-center justify-content-center gap-2">
          <span class="font-bold white-space-nowrap">
            {{ selectedProperty ? 'Edit signature' : 'New signature' }}
          </span>
        </div>
      </ng-template>
      <div>
        <p-steps
          *ngIf="showSignatureDialog"
          [model]="items"
          [activeIndex]="activeIndex"
          (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
        @if(visible) {
        <div class="mt-6" style="min-height: 30vh;">
          <app-machines-selector
            [data]="machines"
            (onMachineSelected)="handleOnMachineSelected($event)"
            [hidden]="activeIndex !== 0"></app-machines-selector>
          <app-labels-selector
            (onLabelSelected)="handleOnLabelSelected($event)"
            [hidden]="activeIndex !== 1"></app-labels-selector>
          @if(selectedMachine) {
          <app-tags-selector
            [trainId]="selectedMachine?.trainId"
            (onTagSelected)="handleOnTagsSelected($event)"
            [hidden]="activeIndex !== 2"></app-tags-selector>
          }
          <div [hidden]="activeIndex !== 3">
            <div class="flex flex-col gap-4" *ngIf="showSignatureDialog">
              <form
                [formGroup]="signatureForm"
                class="signature-selection-form-container flex gap-8"
                style="justify-content: space-around;">
                <div class="flex flex-col gap-2 mb-4">
                  <label for="RANGE">Range</label>
                  <p-calendar
                    [readonlyInput]="true"
                    (onSelect)="onCalendarClose($event)"
                    formControlName="RANGE"
                    selectionMode="range"
                    [showIcon]="true"></p-calendar>
                  <small
                    *ngIf="
                      signatureForm.get('RANGE')?.touched &&
                      signatureForm.get('RANGE')?.errors
                    "
                    class="p-error">
                    <ng-container
                      class="text-red-500"
                      *ngIf="signatureForm.get('RANGE')?.errors?.['required']">
                      Range is required.
                    </ng-container>
                    <ng-container
                      *ngIf="
                        signatureForm.get('RANGE')?.errors?.['rangeIncomplete']
                      ">
                      Please select both start and end dates.
                    </ng-container>
                  </small>
                </div>
                <!-- <app-property-form
                    #propertyForm
                    [property]="selectedLabel?.PROPERTIES[0]"
                    [mode]="'fill'"></app-property-form> -->
              </form>
              <div class="w-full">
                @if(chartSeries) {
                <app-timeseries
                  (rangeSelected)="handleRangeSelected($event)"
                  [chartData]="chartSeries"></app-timeseries>
                } @else {
                <div class="flex items-center justify-center h-96">
                  <p-progressSpinner></p-progressSpinner>
                </div>
                }
                <app-properties-fill
                  #propertiesFillComponent
                  (allPropertiesFilled)="handlePropertiesFilled($event)"
                  [data]="selectedLabel?.PROPERTIES"></app-properties-fill>
              </div>
            </div>
          </div>
          <ng-container *ngIf="activeIndex === 4">
            @if(isLoading) {
            <div class="flex items-center justify-center h-96">
              <p-progressSpinner></p-progressSpinner>
            </div>
            } @else {
            <div class="m-16 bg-white shadow-md rounded-lg p-6">
              <div class="flex justify-between items-center mb-4">
                <span class="font-semibold text-gray-700"
                  >Selected Machine:</span
                >
                <span class="text-gray-500">{{
                  selectedMachine.trainName
                }}</span>
              </div>

              <div class="flex justify-between items-center mb-4">
                <span class="font-semibold text-gray-700">Label:</span>
                <span class="text-gray-500">{{
                  selectedLabel?.LABEL_NAME
                }}</span>
              </div>

              <div class="flex flex-col items-baseline justify-between">
                @for (pp of mappedProperties; track pp) {
                <div class="w-full mb-4">
                  <div class="flex justify-between">
                    <span class="font-semibold text-gray-700"
                      >Property {{ pp.PROPERTY_NAME }}:</span
                    >
                    <span class="text-gray-500">
                      {{ pp.PROPERTY_DATA_VALUES_FILLED }}</span
                    >
                  </div>
                </div>
                }
              </div>

              <div class="flex justify-between items-center mb-4">
                <span class="font-semibold text-gray-700">Tags:</span>
                <span class="text-gray-500">
                  <ng-container
                    *ngFor="let tag of selectedTags; let last = last">
                    {{ tag.tagAlias }}<span *ngIf="!last">, </span>
                  </ng-container>
                </span>
              </div>

              <div class="flex justify-between items-center mb-4">
                <span class="font-semibold text-gray-700">Range Start:</span>
                <span class="text-gray-500">{{
                  signatureForm.value.RANGE[0] | customLocalDate : 'medium'
                }}</span>
              </div>
              <div class="flex justify-between items-center mb-4">
                <span class="font-semibold text-gray-700">Range End:</span>
                <span class="text-gray-500">{{
                  signatureForm.value.RANGE[1] | customLocalDate : 'medium'
                }}</span>
              </div>
            </div>
            }
          </ng-container>
        </div>
        }
      </div>
      <ng-template pTemplate="footer">
        <ng-container *ngIf="activeIndex === 0">
          <p-button
            (click)="visible = false"
            [outlined]="true"
            [disabled]="isNextButtonDisabled()"
            label="Cancel"
            pAutoFocus
            [autofocus]="true"></p-button>
        </ng-container>
        <ng-container *ngIf="activeIndex !== 0">
          <p-button
            (click)="prevStep()"
            [outlined]="true"
            label="Back"
            pAutoFocus
            [autofocus]="true"></p-button>
        </ng-container>
        <ng-container *ngIf="activeIndex === 4">
          <p-button
            icon="pi pi-check"
            (click)="save()"
            [disabled]="isNextButtonDisabled()"
            label="{{ selectedProperty ? 'Save' : 'Create' }}"
            pAutoFocus
            [autofocus]="true"></p-button>
        </ng-container>
        <ng-container *ngIf="activeIndex !== 4">
          <p-button
            (click)="nextStep()"
            label="Next"
            pAutoFocus
            [disabled]="isNextButtonDisabled()"
            [autofocus]="true"></p-button>
        </ng-container>
      </ng-template>
    </p-dialog>

    <p-confirmDialog>
      <ng-template pTemplate="message" let-message>
        <div
          class="flex flex-col text-center align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
          <p>{{ message.message }}</p>
          <div>
            <p>
              Please type in the reason for <strong>DELETE</strong> in order to
              perform this action
            </p>
            <input
              type="text"
              class="mt-4"
              style="width: 100%;"
              pInputText
              (ngModelChange)="onReasonChange($event)"
              [(ngModel)]="deleteReason" />
          </div>
        </div>
      </ng-template>
    </p-confirmDialog>
  `,
  styles: [
    `
      .table-header {
        background-color: #cf9bff40;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
      }

      .text-small-strong {
        color: #8a00e5 !important;
      }

      td {
        border-right: 1px solid #969696;
      }

      .table-header-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }

      .signature-selection-form-container {
        text-align: center;
        margin-top: 2rem;
        flex-wrap: wrap;
      }
      .p-autocomplete-multiple-container {
        width: 100%;
      }
    `,
  ],
})
export class SignaturesComponent implements OnInit {
  user!: any
  items: MenuItem[] | undefined
  multiSortMeta: SortMeta[] = [{ field: 'EVENT_CREATED_DATETIME', order: -1 }]
  selectedProperty = null
  deleteReason = ''
  types = [
    { name: 'Boolean', code: 'NY' },
    { name: 'String', code: 'RM' },
    { name: 'Number', code: 'LDN' },
  ]
  labelsOptions!: any[]
  isLoading = false
  isLoadingSignatures = false
  selectedMachine!: any
  @ViewChild('propertyForm') propertyFormComponent!: PropertyFormComponent
  @ViewChild('propertiesFillComponent')
  propertiesFillComponent!: PropertiesFillComponent

  cols: any[] = [
    {
      field: 'ASSET.drivers',
      header: 'Driver Reference',
      align: 'default',
      type: 'text',
    },
    {
      field: 'ASSET.trainName',
      header: 'Machine name',
      align: 'default',
      type: 'text',
    },
    {
      field: 'EVENT_START_DATE',
      header: 'Start time',
      align: 'default',
      type: 'date',
    },
    {
      field: 'EVENT_END_DATE',
      header: 'End time',
      align: 'default',
      type: 'date',
    },
    { field: 'tags', header: 'Tags', align: 'default' },
    { field: 'LABEL_NAME', header: 'Label', align: 'default', type: 'text' },
    // { field: 'properties', header: 'Properties', align: 'default' },
    {
      field: 'EVENT_CREATED_USER_ID',
      header: 'Created by',
      align: 'default',
      type: 'text',
    },
    {
      field: 'EVENT_CREATED_DATETIME',
      header: 'Created at',
      align: 'default',
      type: 'date',
    },
    {
      field: 'EVENT_LAST_UPDATED_DATETIME',
      header: 'Updated at',
      align: 'default',
      type: 'date',
    },
  ]

  data!: any[]
  selectedColumns = this.cols
  visible: boolean = false
  showSignatureDialog: boolean = false
  showSignatureDetailDialog: boolean = false
  selectedSignatureIndex: number = 0
  signatureForm!: FormGroup
  activeIndex: number = 0
  tags!: any[]
  tagsSuggestion!: any[]
  selectedTags!: any[]
  chartSeries!: any
  selectedLabel!: any
  machines!: any[]
  propertiesFilled!: any
  allPropertiesFilled = false
  mappedProperties!: any

  private signaturesService = inject(SignaturesService)
  private confirmationService = inject(ConfirmationService)
  private messageService = inject(MessageService)
  private starmsService = inject(StarmsService)
  private labelsService = inject(LabelsService)

  ngOnInit(): void {
    this.items = [
      { label: 'Machine', icon: 'pi pi-fw pi-file-edit' },
      { label: 'Label selection', icon: 'pi pi-fw pi-tag' },
      { label: 'Tags selection', icon: 'pi pi-fw pi-tag' },
      { label: 'Time Range selection', icon: 'pi pi-fw pi-tag' },
      { label: 'Confirmation', icon: 'pi pi-fw pi-id-card' },
    ]

    this.setupSignaturesForm()
    this.fetchSignatures()
    this.fetchLabels()
    this.getMachines()
  }

  onReasonChange(event: any) {
    this.disableAcceptButton(event)
  }

  disableAcceptButton(event: any) {
    let acceptButton = document.querySelector('.p-confirm-dialog-accept')
    if (acceptButton) {
      ;(acceptButton as HTMLButtonElement).disabled = event
        ? event === ''
        : true
    }
  }

  resetSort() {
    this.multiSortMeta = [...this.multiSortMeta] // Reset to the initial sort state
  }

  setupSignaturesForm() {
    const today = new Date()
    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(today.getDate() - 7)

    const dateRange = [oneWeekAgo, today]

    this.signatureForm = new FormGroup({
      RANGE: new FormControl(dateRange, [
        Validators.required,
        this.rangeValidator,
      ]),
      tags: new FormControl([]),
      properties: new FormControl(''),
      update: new FormControl(''),
    })

    // this.signatureForm.get('RANGE')?.valueChanges.subscribe((RANGE) => {
    //   if (RANGE[0] <= RANGE[1]) {
    //     this.getTimeSeries()
    //     console.log(RANGE)
    //   }
    // })
  }

  onCalendarClose(event: any) {
    const RANGE = this.signatureForm.get('RANGE')?.value
    if (RANGE[0] <= RANGE[1]) {
      this.getTimeSeries()
      console.log(RANGE)
    }
  }

  get labelsArray(): FormArray {
    return this.signatureForm.get('labels') as FormArray
  }

  getPropertiesArray(labelIndex: number): FormArray {
    return this.labelsArray.at(labelIndex).get('properties') as FormArray
  }

  showDialog(data?: any) {
    this.visible = true
    this.showSignatureDialog = true

    if (data) {
      this.selectedProperty = data
      this.signatureForm.patchValue({
        name: data.name,
        description: data.description,
        type: data.type,
        options: data.options,
        default: data.default,
      })
    } else {
      this.selectedProperty = null
    }
  }

  showSignatureDetail(rowData: any) {
    this.selectedSignatureIndex = this.data.indexOf(rowData)
    this.showSignatureDetailDialog = true
  }

  onActiveIndexChange(event: number) {
    this.activeIndex = event
    if (this.activeIndex === 3) {
      this.propertiesFilled = null
    }
  }

  fetchLabels() {
    this.isLoading = true
    this.isLoadingSignatures = true
    this.labelsService
      .getLabels()
      .pipe(
        finalize(() => {
          this.isLoading = false
          this.isLoadingSignatures = false
        })
      )
      .subscribe({
        next: (labels) => {
          this.labelsOptions = labels
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error fetching labels',
            life: 3000,
          })
        },
      })
  }

  getTimeSeries() {
    const currentDate = new Date()
    const pastDate = new Date(currentDate)
    pastDate.setDate(currentDate.getDate() - 7)

    // Check if RANGE is available and is an array of two elements
    const rangeValue = this.signatureForm.get('RANGE')?.value
    const isRangeValid = Array.isArray(rangeValue) && rangeValue.length === 2

    // If the range is valid, use it; otherwise, fall back to the default dates
    const from = isRangeValid
      ? new Date(rangeValue[0]).toISOString()
      : pastDate.toISOString()
    const to = isRangeValid
      ? new Date(rangeValue[1]).toISOString()
      : currentDate.toISOString()

    const payload = {
      ts_from: from,
      ts_to: to,
      sample_rate: 60,
      tagIds: this.selectedTags.map((tag) => tag.tagId),
    }

    if (this.chartSeries) {
      this.chartSeries = null
    }

    this.starmsService.getTimeSeries(payload).subscribe({
      next: (res) => {
        this.chartSeries = Object.keys(res).map((key) => {
          const tagAlias =
            this.selectedTags.find((tag) => tag.tagId === +key)?.tagAlias || key
          return {
            name: tagAlias,
            data: res[key].map(
              (point: { datetime: string | number | Date; value: any }) => [
                new Date(point.datetime).getTime(),
                point.value !== null ? point.value : null,
              ]
            ),
          }
        })
      },
      error: () => {},
    })
  }

  nextStep() {
    this.activeIndex++
    if (this.activeIndex === 3) {
      this.getTimeSeries()
    }
    if (this.activeIndex === 4) {
      this.mappedProperties = this.propertiesFillComponent?.data
      this.propertiesFilled = this.propertiesFillComponent?.data.reduce(
        (
          acc: { [key: string]: any },
          prop: {
            PROPERTY_DATA_VALUES_FILLED: any
            PROPERTY_ID: string
            PROPERTY_DATA_OPERAND: string
          }
        ) => {
          // Check if PROPERTY_DATA_VALUES_FILLED is undefined, then check for PROPERTY_DATA_OPERAND
          if (prop.PROPERTY_DATA_VALUES_FILLED !== undefined) {
            acc[prop.PROPERTY_ID] = prop.PROPERTY_DATA_VALUES_FILLED
          } else if (prop.PROPERTY_DATA_OPERAND === 'ANNOTATION') {
            acc[prop.PROPERTY_ID] = '' // Put empty string for 'ANNOTATION'
          } else {
            acc[prop.PROPERTY_ID] = null // Default to null
          }

          return acc
        },
        {}
      )
    }
  }

  prevStep() {
    this.activeIndex--
  }

  save() {
    this.isLoading = true
    this.createSignatures()
  }

  isNextButtonDisabled(): boolean {
    return (
      (this.activeIndex === 0 && !this.selectedMachine) ||
      (this.activeIndex === 1 && !this.selectedLabel) ||
      (this.activeIndex === 2 && !this.selectedTags) ||
      (this.activeIndex === 3 &&
        this.signatureForm.get('RANGE')?.errors?.['rangeIncomplete']) ||
      (this.activeIndex === 3 && !this.allPropertiesFilled) ||
      this.isLoading
    )
  }

  confirm(data: { EVENT_ID: string }) {
    this.confirmationService.confirm({
      header: 'Delete property',
      message: 'Are you sure that you want to proceed?',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      acceptButtonStyleClass: 'p-button-sm',
      accept: () => {
        this.isLoading = true
        this.messageService.add({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'You have deleted the signature',
          life: 3000,
        })
        this.signaturesService.deleteSignature(data.EVENT_ID).subscribe({
          next: () => {
            this.fetchSignatures()
          },
        })
        this.deleteReason = ''
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have canceled',
          life: 3000,
        })
        this.deleteReason = ''
      },
    })
    setTimeout(() => {
      this.disableAcceptButton(null)
    }, 20)
  }

  getMachines() {
    this.isLoading = true
    this.starmsService
      .getTrains()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (machines) => {
          this.machines = machines
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error fetching machines',
            life: 3000,
          })
        },
      })
  }

  fetchSignatures() {
    this.isLoading = true
    this.signaturesService
      .getSignatures()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (signatures) => {
          this.data = signatures
          this.isLoading = false
          this.isLoadingSignatures = false
        },
        error: () => {
          this.isLoading = false
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error fetching signatures',
            life: 3000,
          })
          this.isLoadingSignatures = false
        },
      })
  }

  createSignatures() {
    this.isLoading = true
    this.isLoadingSignatures = true
    const query = {
      user_id: localStorage.getItem('userId'),
      event_start_date: this.signatureForm.value.RANGE[0].toISOString(),
      event_end_date: this.signatureForm.value.RANGE[1].toISOString(),
      event_label_id: this.selectedLabel?.LABEL_ID,
    }
    const payload = {
      asset: {
        ...this.selectedMachine,
        tags: this.selectedTags.map((tag) => ({
          tagId: tag.tagId,
          tagAlias: tag.tagAlias,
        })),
      },
      event_property_value: this.propertiesFilled,
    }
    this.signaturesService
      .createSignature(query, payload)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (signatures) => {
          this.fetchSignatures()
          this.visible = false
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Signature created',
            life: 3000,
          })
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error creating the signature',
            life: 3000,
          })
          this.isLoadingSignatures = true
        },
      })
  }

  handleOnMachineSelected($event: any) {
    this.selectedMachine = $event
  }

  handleOnTagsSelected($event: any) {
    this.selectedTags = $event
  }

  handleOnLabelSelected($event: any) {
    this.selectedLabel = $event
  }

  handleRangeSelected($event: { min: Date; max: Date }) {
    this.signatureForm.get('RANGE')?.patchValue([$event.min, $event.max])
  }

  handleDialogOnHide() {
    this.showSignatureDialog = false
    this.activeIndex = 0
  }

  handleSignatureDialogOnHide() {
    this.showSignatureDetailDialog = false
  }

  resolveFieldData = (data: any, field: string) =>
    ObjectUtils.resolveFieldData(data, field)
      ? String(ObjectUtils.resolveFieldData(data, field))
      : ''

  rangeValidator(control: FormControl): { [key: string]: boolean } | null {
    const value = control.value
    if (value && Array.isArray(value)) {
      const [startDate, endDate] = value
      if (startDate !== null && endDate !== null) {
        return null
      }
    }
    return { rangeIncomplete: true }
  }

  handlePropertiesFilled(value: boolean) {
    this.allPropertiesFilled = value
  }
}
