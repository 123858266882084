import { Component, OnInit, Inject } from '@angular/core'
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular'
import { RedirectRequest } from '@azure/msal-browser'

@Component({
  selector: 'app-signout',
  template: `
    <div class="fluid-container glass-background ">
      <div class="row">
        <div class="col">
          <div class="card card-custom">
            <div class="card-body">
              <h1 class="primary-text"></h1>
              <button class="btn btn-primary" (click)="loginRedirect()">
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: `
    .signout-container {
      width: 400px;
      text-align: center;
      border: solid 1px #9bafbe;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -90%);
      box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);

      &__title {
        font-weight: bold !important;
        font-weight: 400;
        text-align: center;
        margin: 20px;
        padding-bottom: 5px;
        border-bottom: 1px solid #9bafbe;
      }

      &__button {
        color: white;
        background-color: #239196;
        margin: 10px
      }
    }

    .bg-image {
      background-image: url("/assets/images/background-image.png");
    }

    .glass-background {
      background: url('/assets/images/background-image.png') center/cover fixed;
      backdrop-filter: blur(10px);
      height: 93.4vh;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    .primary-text {
      color: white;
    }

    .btn {
      width: 99%;
      opacity: 100%;
    }

    .card-custom {
      opacity: 80%; background-color: black;
    }
  `,
  standalone: true,
})
export class LoginComponent implements OnInit {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
  ) {}

  ngOnInit() {}

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest)
    } else {
      this.authService.loginRedirect()
    }
  }
}
