import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule],
  template: ` <div class="iframe-container">
    <iframe
      style="height:89vh"
      [src]="iframeUrl"
      width="100%"
      frameborder="0"></iframe>
      iframeUrl
  </div>`,
  styles: [``],
})
export class ContactComponent implements OnInit {
  iframeUrl!: SafeResourceUrl

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    console.log('init')
    const queryParams = new URLSearchParams({
      solution: 'Labelling',
    }).toString()
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.SUPPORT_APP_URL}/Home?${queryParams}`
    )
  }
}
