import { CommonModule } from '@angular/common'
import { Component, OnInit, ViewChild, inject } from '@angular/core'
import { TabMenuModule } from 'primeng/tabmenu'
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { SortMeta } from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect'
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { DialogModule } from 'primeng/dialog'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { PropertiesSelectorComponent } from '../properties/properties-selection.component'
import { LabelsService } from '../../shared/services/labels.service'
import { finalize } from 'rxjs'
import { ObjectUtils } from 'primeng/utils'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { CustomLocalDatePipe } from '../../shared/pipes/custom-date-time.pipe'

@Component({
  selector: 'app-labels',
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ConfirmDialogModule,
    DropdownModule,
    PropertiesSelectorComponent,
    ProgressSpinnerModule,
    CustomLocalDatePipe,
  ],
  providers: [ConfirmationService],
  template: `
    <div class="pt-4 px-4 grid">
      <p-button
        label="New label"
        (onClick)="showDialog()"
        class="rounded-lg pb-4"
        icon="pi pi-plus"></p-button>

      <p-table
        #dt
        [columns]="selectedColumns"
        [value]="data"
        dataKey="LABEL_ID"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="50"
        [loading]="isLoading"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        sortMode="multiple"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
        [scrollable]="true"
        [multiSortMeta]="multiSortMeta"
        scrollHeight="53vh">
        <ng-template pTemplate="caption">
          <div class="table-header-container" style="padding-bottom: 1rem">
            <h2>List of Labels</h2>
            <p-multiSelect
              [options]="cols"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ 'min-width': '200px' }"
              placeholder="Choose Columns"></p-multiSelect>
            <div style="display: flex; gap: 1.5rem;">
              <p-button
                class="secondary-btn"
                [rounded]="true"
                label="Reset sorting"
                (click)="resetSort()"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.13843 6.75101L8.19576 5.80768L11.3338 2.66968L14.4718 5.80768L13.5291 6.75101L12.0004 5.22234V12.667H10.6671V5.22234L9.13843 6.75101Z"
                      fill="#8A00E5" />
                    <path
                      d="M3.99996 10.7779V3.33328H5.33396V10.7779L6.86196 9.24928L7.8053 10.1919L4.6673 13.3299L1.5293 10.1919L2.47196 9.24928L3.99996 10.7779Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
              <p-button
                class="secondary-btn"
                [rounded]="true"
                label="Reset filtering"
                (click)="dt.reset()"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.31998 9.33333L1.33398 2H14.6673L8.66732 9.33333V14H7.33398V9.33333H7.31998ZM11.854 3.33333H4.14398L7.95332 8H8.03598L11.854 3.33333Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
              <p-button
                class="secondary-btn"
                [rounded]="true"
                label="Download csv"
                (click)="dt.exportCSV()"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.5727 6.15333L12.426 7.18L7.99935 10.8667L3.57268 7.18L4.42602 6.15333L7.33268 8.58V2H8.66602V8.58L11.5727 6.15333Z"
                      fill="#8A00E5" />
                    <path
                      d="M13.3327 14V12.6667H2.66602V14H13.3327Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 5rem"></th>
            <th
              class="table-header text-small-strong"
              *ngFor="let col of columns"
              pSortableColumn="{{ col.field }}">
              <div style="display: flex; align-items: center">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                  class="mr-auto"></p-sortIcon>
              </div>
            </th>
          </tr>
          <tr>
            <th></th>
            <th style="min-width: 14vw;" *ngFor="let col of columns">
              @if (col.type) {

              <p-columnFilter
                [type]="col.type"
                matchMode="contains"
                [field]="col.field"></p-columnFilter>
              }
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-expanded="expanded">
          <tr class="text-small-roman">
            <td>
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="rowData"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="
                  expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                "></button>
            </td>
            <td *ngFor="let col of columns">
              @if (col.field === 'actions') {
              <div class="flex gap-4">
                <p-button
                  icon="pi pi-pencil"
                  [rounded]="true"
                  (onClick)="showDialog(rowData)"
                  severity="help"
                  [outlined]="true"></p-button>
                <p-button
                  icon="pi pi-trash"
                  [rounded]="true"
                  (onClick)="confirm(rowData)"
                  severity="danger"
                  [outlined]="true"></p-button>
              </div>
              } @else if (col.field === 'properties'){
              <span [pRowToggler]="rowData"> Click to expand </span>
              } @else if (col.field === 'LABEL_LAST_UPDATED_DATETIME'){
              {{ rowData[col.field] | customLocalDate : 'medium' }}
              } @else if(col.field === 'LABEL_LAST_UPDATED_USER_ID') {
              {{ rowData['LABEL_LAST_UPDATED_USER_LAST_NAME'] }},
              {{ rowData['LABEL_LAST_UPDATED_USER_GIVEN_NAME'] }}
              ( {{ rowData[col.field] }} ) }@else {
              {{ rowData[col.field] }}
              }
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-label>
          <tr>
            <td colspan="7">
              <div class="p-3">
                <p-table [value]="label.PROPERTIES" dataKey="name">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="PROPERTY_NAME">
                        Name <p-sortIcon field="PROPERTY_NAME"></p-sortIcon>
                      </th>
                      <th pSortableColumn="PROPERTY_DESCRIPTION">
                        Description
                        <p-sortIcon field="PROPERTY_DESCRIPTION"></p-sortIcon>
                      </th>
                      <th pSortableColumn="type">
                        Type <p-sortIcon field="date"></p-sortIcon>
                      </th>
                      <th pSortableColumn="default">
                        Options <p-sortIcon field="status"></p-sortIcon>
                      </th>
                      <th style="width: 4rem"></th>
                    </tr>
                  </ng-template>
                  <!-- <ng-template pTemplate="body" let-order>
                            <tr>
                                <td>asd {{ order.id }}</td>
                                <td>{{ order.customer }}</td>
                            </tr>
                        </ng-template> -->
                  <ng-template pTemplate="body" let-property>
                    <tr>
                      <td>{{ property.PROPERTY_NAME }}</td>
                      <td>{{ property.PROPERTY_DESCRIPTION }}</td>
                      <td>{{ property.PROPERTY_DATA_OPERAND }}</td>
                      <td>{{ property.PROPERTY_DATA_VALUES }}</td>
                      <!-- <td>{{ property.default }}</td> -->
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td style="border: none;" colspan="5">
              No results for your search terms.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <p-dialog
      header="Header"
      (onHide)="handleDialogOnHide()"
      [(visible)]="visible"
      [modal]="true"
      [style]="{ width: '80rem' }"
      [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
      <ng-template pTemplate="header">
        <div
          class="inline-flex align-items-center justify-content-center gap-2">
          <span class="font-bold white-space-nowrap">{{
            selectedLabel ? 'Edit label' : 'New label'
          }}</span>
        </div>
      </ng-template>
      @if(isLoading) {
      <div class="flex items-center justify-center h-96">
        <p-progressSpinner></p-progressSpinner>
      </div>
      } @else {

      <div>
        <form [formGroup]="labelForm">
          <div class="flex flex-col gap-2 mb-4">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" formControlName="name" />
            <small
              class="text-red-500"
              *ngIf="
                labelForm.controls['name'].touched &&
                labelForm.controls['name'].invalid
              ">
              Name is required.
            </small>
          </div>
          <div class="flex flex-col gap-2 mb-4">
            <label for="name">Description</label>
            <textarea
              type="text"
              pInputTextarea
              rows="5"
              cols="30"
              id="description"
              formControlName="description"></textarea>
            <small
              class="text-red-500"
              *ngIf="
                labelForm.controls['description'].touched &&
                labelForm.controls['description'].invalid
              ">
              Description is required.
            </small>
          </div>
          @if (selectedLabel) {
          <div class="flex flex-col gap-2 mb-4">
            <label for="name">Update reason</label>
            <input
              type="text"
              pInputText
              id="update"
              formControlName="update" />
          </div>
          }
          <div class="flex flex-col gap-2">
            <label for="name">Properties</label>
            @if (visible) {

            <app-properties-selector
              [selection]="selectedProperties"
              (selectionChanged)="
                handleSelectionChanged($event)
              "></app-properties-selector>
            }
          </div>
        </form>
      </div>
      }
      <ng-template pTemplate="footer">
        <p-button
          (click)="visible = false"
          [outlined]="true"
          label="Cancel"
          [disabled]="isLoading"
          pAutoFocus
          [autofocus]="true"></p-button>
        @if (selectedLabel) {
        <p-button
          icon="pi pi-check"
          (click)="saveLabel()"
          [disabled]="
            !labelForm.controls['name'].valid ||
            !labelForm.controls['description'].valid ||
            isLoading
          "
          label="Save"
          pAutoFocus
          [autofocus]="true"></p-button>
        } @else {
        <p-button
          icon="pi pi-check"
          (click)="createLabel()"
          [disabled]="
            !labelForm.controls['name'].valid ||
            !labelForm.controls['description'].valid ||
            isLoading
          "
          label="Create"
          pAutoFocus
          [autofocus]="true"></p-button>
        }
      </ng-template>
    </p-dialog>

    <p-confirmDialog #confirmDialog>
      <ng-template pTemplate="message" let-message>
        <div
          class="flex flex-col text-center align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
          <p>{{ message.message }}</p>
          <div>
            <p>
              Please type in the reason for <strong>DELETE</strong> in order to
              perform this action
            </p>
            <input
              type="text"
              class="mt-4"
              style="width: 100%;"
              pInputText
              (ngModelChange)="onReasonChange($event)"
              [(ngModel)]="deleteReason" />
          </div>
        </div>
      </ng-template>
    </p-confirmDialog>
  `,
  styles: [
    `
      .table-header {
        background-color: #cf9bff40;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
      }

      .text-small-strong {
        color: #8a00e5 !important;
      }

      td {
        border-right: 1px solid #969696;
      }

      .table-header-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    `,
  ],
})
export class LabelsComponent implements OnInit {
  user!: any
  items: MenuItem[] | undefined
  multiSortMeta: SortMeta[] = [
    { field: 'LABEL_LAST_UPDATED_DATETIME', order: -1 },
  ]
  selectedLabel = null
  deleteReason = ''
  types = [
    { name: 'Boolean', code: 'NY' },
    { name: 'String', code: 'RM' },
    { name: 'Number', code: 'LDN' },
  ]
  isLoading = false
  selectedProperties: any[] = []

  cols: any[] = [
    { field: 'LABEL_NAME', header: 'Name', align: 'default', type: 'text' },
    {
      field: 'LABEL_DESCRIPTION',
      header: 'Description',
      align: 'default',
      type: 'text',
    },
    {
      field: 'LABEL_LAST_UPDATED_USER_ID',
      header: 'Last updated by',
      align: 'default',
      type: 'text',
    },
    {
      field: 'LABEL_LAST_UPDATED_DATETIME',
      header: 'Last updated time',
      align: 'default',
    },
    { field: 'properties', header: 'Properties', align: 'default' },
    // { field: 'type', header: 'Type', align: 'default' },
    // { field: 'options', header: 'Options', align: 'default' },
    // { field: 'default', header: 'Default values', align: 'default' },
    { field: 'actions', header: 'Actions', align: 'default' },
  ]

  data!: any[]
  selectedColumns = this.cols
  visible: boolean = false
  labelForm!: FormGroup
  @ViewChild('confirmDialog') confirmDialog!: any

  private labelsService = inject(LabelsService)
  private confirmationService = inject(ConfirmationService)
  private messageService = inject(MessageService)

  ngOnInit(): void {
    this.items = [
      { label: 'Signatures', icon: 'pi pi-fw pi-file-edit' },
      { label: 'Labels', icon: 'pi pi-fw pi-tag' },
      { label: 'Properties', icon: 'pi pi-fw pi-id-card' },
    ]

    this.labelForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      id: new FormControl(''),
      update: new FormControl(''),
    })

    this.fetchLabels()
  }

  fetchLabels() {
    this.isLoading = true
    this.labelsService
      .getLabels()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (labels) => {
          this.data = labels
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error fetching labels',
            life: 3000,
          })
        },
      })
  }

  showDialog(data?: any) {
    this.visible = true
    if (data) {
      this.selectedLabel = data
      this.labelForm.patchValue({
        name: data.LABEL_NAME,
        description: data.LABEL_DESCRIPTION,
        id: data.LABEL_ID,
      })
      this.selectedProperties = data.PROPERTIES
    } else {
      this.labelForm.reset()
      this.selectedLabel = null
    }
  }

  //TODO: add debounce
  onReasonChange(event: any) {
    this.disableAcceptButton(event)
  }

  disableAcceptButton(event: any) {
    let acceptButton = document.querySelector('.p-confirm-dialog-accept')
    if (acceptButton) {
      ;(acceptButton as HTMLButtonElement).disabled = event
        ? event === ''
        : true
    }
  }

  resetSort() {
    this.multiSortMeta = [...this.multiSortMeta] // Reset to the initial sort state
  }

  confirm(data: { LABEL_ID: string }) {
    this.confirmationService.confirm({
      header: 'Delete label',
      message: 'Are you sure that you want to proceed?',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      acceptButtonStyleClass: 'p-button-sm',
      accept: () => {
        this.isLoading = true
        this.messageService.add({
          severity: 'success',
          summary: 'Confirmed',
          detail: 'You have deleted the label',
          life: 3000,
        })
        this.labelsService.deleteLabel(data.LABEL_ID).subscribe({
          next: () => {
            this.fetchLabels()
          },
        })
        this.deleteReason = ''
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have canceled',
          life: 3000,
        })
        this.deleteReason = ''
      },
    })
    setTimeout(() => {
      this.disableAcceptButton(null)
    }, 20)
  }

  saveLabel() {
    this.isLoading = true
    const payload = {
      label_name: this.labelForm.value.name,
      user_id: localStorage.getItem('userId'),
      label_id: this.labelForm.value.id,
      label_description: this.labelForm.value.description,
      label_lookback_period: '1',
      label_property_ids: this.selectedProperties.map(
        (prop) => prop.PROPERTY_ID
      ),
    }

    this.labelsService.updateLabel(payload, this.labelForm.value.id).subscribe({
      next: () => {
        this.visible = false
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Label updated',
          life: 3000,
        })
        this.fetchLabels()
      },
      error: () => {
        this.visible = false
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error saving the label',
          life: 3000,
        })
      },
    })
  }

  createLabel() {
    this.isLoading = true
    const payload = {
      label_name: this.labelForm.value.name,
      user_id: localStorage.getItem('userId'),
      label_description: this.labelForm.value.description,
      label_lookback_period: '1',
      label_property_ids: this.selectedProperties.map(
        (prop) => prop.PROPERTY_ID
      ),
    }

    this.labelsService.createLabel(payload).subscribe({
      next: () => {
        this.visible = false
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Label created',
          life: 3000,
        })
        this.fetchLabels()
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error creating the label',
          life: 3000,
        })
      },
    })
  }

  resolveFieldData = (data: any, field: string) =>
    ObjectUtils.resolveFieldData(data, field)
      ? String(ObjectUtils.resolveFieldData(data, field))
      : ''

  handleSelectionChanged(selection: any) {
    this.selectedProperties = selection
  }

  handleDialogOnHide() {
    this.visible = false
    this.selectedProperties = []
  }
}
