import { CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  FormArray,
} from '@angular/forms'
import { PropertiesService } from '../../shared/services/properties.service'
import { DropdownModule } from 'primeng/dropdown'
import { InputTextModule } from 'primeng/inputtext'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ButtonModule } from 'primeng/button'
import { MessageService } from 'primeng/api'

@Component({
  selector: 'app-property-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
  ],
  template: `
    <div>
      <form [formGroup]="propertyForm">
        <div class="flex flex-col gap-2 mb-4" *ngIf="mode !== 'fill'">
          <label for="name">Name</label>
          <input type="text" pInputText id="name" formControlName="name" />
          <small
            *ngIf="
              propertyForm.controls['name'].touched &&
              propertyForm.controls['name'].invalid
            "
            class="text-red-500">
            Name is required.
          </small>
        </div>
        <div class="flex flex-col gap-2 mb-4" *ngIf="mode !== 'fill'">
          <label for="description">Description</label>
          <textarea
            type="text"
            pInputTextarea
            rows="5"
            cols="30"
            id="description"
            formControlName="description"></textarea>
          <small
            *ngIf="
              propertyForm.controls['description'].touched &&
              propertyForm.controls['description'].invalid
            "
            class="text-red-500">
            Description is required.
          </small>
        </div>
        <div class="flex flex-col gap-2 mb-4" *ngIf="mode !== 'fill'">
          <label for="type">Type</label>
          <p-dropdown
            formControlName="type"
            optionValue="code"
            [options]="types"
            optionLabel="name"
            placeholder="Select a type"></p-dropdown>
          <small
            *ngIf="
              propertyForm.controls['type'].touched &&
              propertyForm.controls['type'].invalid
            "
            class="text-red-500">
            Type is required.
          </small>
        </div>
        <div
          class="flex flex-col gap-2 mb-4"
          *ngIf="options.length > 0 && mode !== 'fill'">
          <label for="options">Options</label>
          <div formArrayName="options" class="space-y-2">
            <div
              *ngFor="let option of options.controls; let i = index"
              class="flex items-center gap-2">
              <input
                type="text"
                pInputText
                [formControlName]="i"
                class="flex-1" />
              <button
                type="button"
                pButton
                icon="pi pi-minus"
                (click)="removeOptionControl(i)"
                class="p-button-danger p-button-sm"
                *ngIf="
                  propertyForm.controls['type'].value === 'CHOICE'
                "></button>
            </div>
            <button
              type="button"
              pButton
              icon="pi pi-plus"
              (click)="addOptionControl()"
              class="p-button-success p-button-sm mt-2"
              *ngIf="propertyForm.controls['type'].value === 'CHOICE'"></button>
          </div>
          <small *ngIf="options.invalid" class="text-red-500">
            Options are required.
          </small>
        </div>
        <div class="flex flex-col gap-2 mb-4" *ngIf="helperMessage">
          <small>{{ helperMessage }}</small>
        </div>
        <div class="flex flex-col gap-2 mb-4" *ngIf="mode === 'fill'">
          <label for="default">{{ property?.PROPERTY_NAME }}</label>
          <input
            type="text"
            pInputText
            id="default"
            formControlName="default" />
        </div>
        <div
          class="flex flex-col gap-2 mb-4"
          *ngIf="property && mode !== 'fill'">
          <label for="update">Update reason</label>
          <input type="text" pInputText id="update" formControlName="update" />
        </div>
      </form>
    </div>
  `,
  styles: [``],
})
export class PropertyFormComponent implements OnInit {
  @Input() property: any
  @Input() mode: 'create' | 'update' | 'fill' = 'create' // Default mode is 'create'
  propertyForm!: FormGroup
  currentUserId = localStorage.getItem('userId')
  types = [
    { name: 'Annotation', code: 'ANNOTATION' },
    { name: 'Choice', code: 'CHOICE' },
    { name: 'Range', code: 'RANGE' },
    { name: 'Minimum', code: 'MINIMUM' },
    { name: 'Maximum', code: 'MAXIMUM' },
    { name: 'Boolean', code: 'BOOLEAN' },
  ]
  helperMessage: string = ''

  constructor(
    private fb: FormBuilder,
    private propertiesService: PropertiesService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initForm()

    if (this.property) {
      this.fillForm(this.property)
    }

    this.propertyForm.get('type')?.valueChanges.subscribe((type) => {
      this.updateValidatorsBasedOnType(type)
    })
  }

  initForm() {
    this.propertyForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      type: ['', Validators.required],
      options: this.fb.array([]),
      default: [''],
      update: [''],
      id: [''],
    })
  }

  fillForm(data: any) {
    this.propertyForm.patchValue({
      name: data.PROPERTY_NAME,
      description: data.PROPERTY_DESCRIPTION,
      type: data.PROPERTY_DATA_OPERAND,
      options: data.PROPERTY_DATA_VALUES,
      default: data.default,
      id: data.PROPERTY_ID,
    })
  }

  updateValidatorsBasedOnType(type: string) {
    this.options.clear()
    const defaultControl = this.propertyForm.get('default')

    if (!defaultControl) return

    this.helperMessage = ''

    switch (type) {
      case 'ANNOTATION':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage = 'Annotations cannot have predefined values.'
        break

      case 'CHOICE':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage = 'Choice requires at least two list elements.'
        this.addOptionControls(2)
        break

      case 'RANGE':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage =
          'Range requires a list of two elements (lower and upper bound).'
        this.addOptionControls(2)
        break

      case 'MINIMUM':
      case 'MAXIMUM':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage = `${
          type.charAt(0).toUpperCase() + type.slice(1)
        } requires a list of only one element.`
        this.addOptionControls(1)
        break

      case 'BOOLEAN':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage =
          'There is no need to provide values for BOOLEAN operand.'
        break

      default:
        defaultControl.clearValidators()
        defaultControl.setValue('')
        break
    }

    defaultControl.updateValueAndValidity()
  }

  addOptionControl() {
    this.options.push(this.fb.control('', Validators.required))
  }

  addOptionControls(count: number) {
    for (let i = 0; i < count; i++) {
      this.options.push(this.fb.control('', Validators.required))
    }
  }

  removeOptionControl(index: number) {
    if (this.options.length > 2) {
      this.options.removeAt(index)
    }
  }

  get options(): FormArray {
    return this.propertyForm.get('options') as FormArray
  }

  saveProperty() {
    let propertyDataValues = this.propertyForm.value.options

    if (
      this.propertyForm.value.type === 'RANGE' ||
      this.propertyForm.value.type === 'MINIMUM' ||
      this.propertyForm.value.type === 'MAXIMUM'
    ) {
      propertyDataValues = propertyDataValues.map((value: string) =>
        Number(value)
      )
    }

    const payload = {
      property_id: this.propertyForm.value.id,
      user_id: this.currentUserId,
      property_name: this.propertyForm.value.name,
      property_description: this.propertyForm.value.description,
      property_data_operand: this.propertyForm.value.type,
      property_data_values: propertyDataValues,
    }

    this.propertiesService
      .updateProperty(payload, this.propertyForm.value.id)
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Property updated',
            life: 3000,
          })
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error updating the property',
            life: 3000,
          })
        },
      })
  }

  createProperty() {
    let propertyDataValues = this.propertyForm.value.options

    if (
      this.propertyForm.value.type === 'RANGE' ||
      this.propertyForm.value.type === 'MINIMUM' ||
      this.propertyForm.value.type === 'MAXIMUM'
    ) {
      propertyDataValues = propertyDataValues.map((value: string) =>
        Number(value)
      )
    }

    const payload = {
      property_data_operand: this.propertyForm.value.type,
      user_id: this.currentUserId,
      property_name: this.propertyForm.value.name,
      property_description: this.propertyForm.value.description,
      property_data_values: propertyDataValues,
    }

    this.propertiesService.createProperty(payload).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Property created',
          life: 3000,
        })
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error creating the property',
          life: 3000,
        })
      },
    })
  }
}
