import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { CardModule } from 'primeng/card'
@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [CommonModule, CardModule],
  template: `
    <div class="pt-4 px-4 grid">
      <p-card header="Signatures" class="grow mb-4" id="signatureCard">
        <p class="m-0">
          Signatures are a structured representation of an event or anomaly
          within a time series dataset. It is created by attaching specific
          labels and properties to a segment of the time series data.
        </p>
      </p-card>
      <div class="grid grid-cols-3 gap-x-4 mb-4">
        <p-card header="Labels" class="flex" id="labelsCard">
          <p class="m-0">
            Labels are characteristics that describe specific observed
            behaviors.
          </p>
          <p class="m-0">
            These labels are independent of the assets and can be as specific as
            intended, depending on the number and specificity of the properties
            assigned to them.
          </p>
        </p-card>
        <p-card header="Properties" class="flex" id="propertiesCard">
          <p class="m-0">
            Characteristics or attributes associated with the labels. Properties
            provide additional context and details about the event.
          </p>
        </p-card>
        <p-card header="Events" class="flex" id="eventsCard">
          <p class="m-0">
            An event is a period during which outstanding behavior exists and is
            worth identifying and classifying, and for that we use the Labels.
          </p>
          <p class="m-0">
            Events are associated with a specific asset and sensor tags within a
            defined time range.
          </p>
        </p-card>
      </div>
      <p-card header="How to use" subheader="Labelling Tool" class="grow mb-4">
        <div class="flex justify-center mb-20">
          <video alt="Card" width="70%" height="70%" controls class="flex">
            <source
              src="assets/videos/how-to-labelling-tool.mp4"
              type="video/mp4" />
          </video>
        </div>
        <ul class="m-4">
          <li class="font-bold">1. Create a Property</li>
          <p class="m-4">
            Navigate to the Properties tab, select the "New property" button and
            fill the required fields.
          </p>
          <p class="m-4">
            Set a name, a description, a type, and the default value for the
            property. The type of property can be a range, a boolean, etc.
          </p>
          <p class="m-4">Save the property.</p>
          <li class="font-bold">2. Create a Label</li>
          <p class="m-4">
            Navigate to the Labels tab, select the "New label" button and fill
            the required fields.
          </p>
          <p class="m-4">
            Set a name, a description, and choose the properties previoulsy
            created, and available in the list to be attached to the new label.
          </p>
          <p class="m-4">Save the label.</p>
          <li class="font-bold">3. Create a Signature</li>
          <p class="m-4">
            Navigate to the Signatures tab, select the "New signature" button
            and fill the required field.
          </p>
          <p class="m-4">
            First, select the device that you want to create the signature for.
            It's possible to filter the devices by multiple fields.
          </p>
          <p class="m-4">
            Proceed, and select the labels you want to be attached to this
            signature. It's possible to expand the label and visualize all the
            properties aggregated to it.
          </p>
          <p class="m-4">
            After that, select all the tahs that relevant for the label.
          </p>
          <p class="m-4">
            In the forth step, you can select a specific range withtin the time
            series presented. And now, you need to fill the values for each
            property previoulsy selected.
          </p>
          <p class="m-4">
            Finally, you just need to confirm all the input data for the
            signature and save it.
          </p>
        </ul>
      </p-card>
    </div>
  `,
  styles: [``],
})
export class FaqComponent implements OnInit {
  ngOnInit(): void {
    throw new Error('Method not implemented.')
  }
}
