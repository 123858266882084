import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  url = `${environment.BASE_URL}/labels`
  private http = inject(HttpClient)

  getLabels(): Observable<any> {
    return this.http.get(`${this.url}/list`)
  }

  updateLabel(payload: any, id: any): Observable<any> {
    const queryParams =
      `user_id=${encodeURIComponent(payload.user_id)}&` +
      `label_name=${encodeURIComponent(payload.label_name)}&` +
      `label_description=${encodeURIComponent(payload.label_description)}&` +
      `label_lookback_period=${encodeURIComponent(
        payload.label_lookback_period
      )}&` +
      `label_property_ids=${encodeURIComponent(payload.label_property_ids)}`

    const fullUrl = `${this.url}/${payload.label_id}/update?${queryParams}`
    return this.http.put(fullUrl, {
      label_property_ids: payload.label_property_ids || [],
    })
  }

  deleteLabel(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}/delete`)
  }

  createLabel(payload: any): Observable<any> {
    const queryParams =
      `user_id=${encodeURIComponent(payload.user_id)}&` +
      `label_name=${encodeURIComponent(payload.label_name)}&` +
      `label_description=${encodeURIComponent(payload.label_description)}&` +
      `label_lookback_period=${encodeURIComponent(
        payload.label_lookback_period
      )}`

    const fullUrl = `${this.url}/create?${queryParams}`
    return this.http.post(fullUrl, {
      label_property_ids: payload.label_property_ids || [],
    })
  }
}
