import { ApplicationConfig } from '@angular/core'
import { importProvidersFrom } from '@angular/core'
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
  provideRouter,
  withDisabledInitialNavigation,
  withEnabledBlockingInitialNavigation,
} from '@angular/router'
import {
  MsalInterceptor,
  MSAL_INSTANCE,
  MsalInterceptorConfiguration,
  MsalGuardConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
} from '@azure/msal-angular'
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
  BrowserUtils,
} from '@azure/msal-browser'
import { routes } from './app.routes'
import { environment } from '../environments/environment'
import { MessageService } from 'primeng/api'

const initialNavigation =
  !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
    ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
    : withDisabledInitialNavigation()

export function loggerCallback(logLevel: LogLevel, message: string) {
  // console.log(message)
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  })
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>()
  protectedResourceMap.set(environment.BASE_URL + '/*/', [
    `${environment.msalConfig.auth.clientId}/.default`,
  ])
  protectedResourceMap.set(environment.STARMS_URL + '/*/', [
    `${environment.msalConfig.auth.clientId}/.default`,
  ])
  protectedResourceMap.set(
    environment.apiConfig.uri,
    environment.apiConfig.scopes
  )

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  }
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed',
  }
}

type CachedRoute = {
  [key: string | symbol]: any
}

const ReuseComponent: string = 'reuseComponent'

export class AppRouteReuseStrategy implements RouteReuseStrategy {
  private cachedRoutes: CachedRoute = {}
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const shouldDetach = !!route.data[ReuseComponent] && !!route.component
    return shouldDetach
  }
  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null
  ): void {
    if (route.routeConfig?.path && handle) {
      this.cachedRoutes[route.routeConfig.path] = handle
    }
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const shouldAttach =
      !!route.routeConfig?.path && !!this.cachedRoutes[route.routeConfig.path]
    return shouldAttach
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    let cachedRoute = null
    if (route.routeConfig?.path) {
      cachedRoute = this.cachedRoutes[route.routeConfig.path]
    }
    return cachedRoute
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    const shouldReuseRoute = future.routeConfig === curr.routeConfig
    return shouldReuseRoute
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, initialNavigation),
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MessageService,
    importProvidersFrom(BrowserAnimationsModule),
  ],
}
