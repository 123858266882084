import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../../core/header/header.component';

@Component({
  selector: 'app-layout',
  template: `
    <app-header></app-header>
    <router-outlet ></router-outlet>
  `,
  styles: `
  `,
  standalone: true,
  imports: [CommonModule, RouterModule, HeaderComponent],
})
export class LayoutComponent {
  // Workaround needed when using msal service, otherwise when logging out it starts a loop
}
