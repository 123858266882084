import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'customLocalDate',
  standalone: true,
})
export class CustomLocalDatePipe implements PipeTransform {
  transform(value: string, format: string = 'medium'): string {
    if (!value) return ''

    // Parse the input date string
    const date = new Date(value)

    // Adjust for the local timezone offset
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )

    // Format the date using the browser's locale settings
    return localDate.toLocaleString(undefined, this.getOptions(format))
  }

  private getOptions(format: string): Intl.DateTimeFormatOptions {
    switch (format) {
      case 'short':
        return {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }
      case 'medium':
        return {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }
      case 'long':
        return {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          timeZoneName: 'short',
        }
      default:
        return {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        }
    }
  }
}
