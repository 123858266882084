import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { TabMenuModule } from 'primeng/tabmenu'
import { ConfirmationService } from 'primeng/api'
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { SortMeta } from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DialogModule } from 'primeng/dialog'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { RadioButtonModule } from 'primeng/radiobutton'

@Component({
  selector: 'app-machines-selector',
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ConfirmDialogModule,
    DropdownModule,
    RadioButtonModule,
  ],
  providers: [ConfirmationService],
  template: `
    <div class="pt-4 px-4 grid">
      <p-table
        #dt
        [columns]="selectedColumns"
        [value]="data"
        [loading]="!data?.length"
        dataKey="trainId"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="50"
        [scrollable]="true"
        selectionMode="single"
        (onRowSelect)="machineSelected()"
        [(selection)]="selectedMachine"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        sortMode="multiple"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
        [scrollable]="true"
        [multiSortMeta]="multiSortMeta"
        scrollHeight="53vh">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th></th>
            <th
              class="table-header text-small-strong"
              *ngFor="let col of columns"
              pSortableColumn="{{ col.field }}">
              <div style="display: flex; align-items: center">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                  class="mr-auto"></p-sortIcon>
              </div>
            </th>
          </tr>
          <tr>
            <th></th>
            <th style="min-width: 14vw;" *ngFor="let col of cols">
              @if (col.type) {

              <p-columnFilter
                [type]="col.type"
                matchMode="contains"
                [field]="col.field"></p-columnFilter>
              }
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-expanded="expanded">
          <tr class="text-small-roman" [pSelectableRow]="rowData">
            <td>
              <p-radioButton
                name="machine"
                [value]="rowData"
                [(ngModel)]="selectedMachine"
                (click)="onRadioButtonClick($event, rowData)">
              </p-radioButton>
            </td>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td style="border: none;" colspan="6">
              No results for your search terms.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [
    `
      .table-header {
        background-color: #cf9bff40;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
      }

      .text-small-strong {
        color: #8a00e5 !important;
      }

      td {
        border-right: 1px solid #969696;
      }

      .table-header-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    `,
  ],
})
export class MachinesSelectorComponent {
  @Input() data!: any
  @Output() onMachineSelected: EventEmitter<any> = new EventEmitter()
  user!: any
  multiSortMeta: SortMeta[] = [{ field: 'ERDAT', order: -1 }]
  selectedMachine = null
  deleteReason = ''
  activeIndex: number = 0

  cols: any[] = [
    { field: 'drivers', header: 'Driver', align: 'default', type: 'text' },
    { field: 'trainName', header: 'Name', align: 'default', type: 'text' },
    {
      field: 'drivenUnitTypes',
      header: 'Driven unit',
      align: 'default',
      type: 'text',
    },
    { field: 'assignedPL', header: 'Branch', align: 'default', type: 'text' },
    { field: 'trainId', header: 'Id', align: 'default', type: 'text' },
  ]

  selectedColumns = this.cols
  visible: boolean = false
  isLoading = false

  machineSelected() {
    this.onMachineSelected.emit(this.selectedMachine)
  }

  onRadioButtonClick(event: any, rowData: any) {
    event.stopPropagation()
    this.selectedMachine = rowData
    this.machineSelected()
  }
}
