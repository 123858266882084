import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class AzureService {
  private currentUserSubject = new BehaviorSubject<any>(null)
  authServiceInstance: any

  constructor(private http: HttpClient) {}

  getUserInfo(): Observable<any> {
    const url = 'https://graph.microsoft.com/v1.0/me'
    return this.http.get(url).pipe(
      tap((userData) => {
        this.currentUserSubject.next(userData)
        localStorage.setItem('user', JSON.stringify(userData))
      })
    )
  }

  getCurrentUser(): Observable<any> {
    return this.currentUserSubject.asObservable()
  }

  getCurrentUserValue(): any {
    return this.currentUserSubject.value
  }

  setAuthServiceInstance(authService: any) {
    this.authServiceInstance = authService
  }

  getAuthServiceInstance() {
    return this.authServiceInstance
  }

  logout() {
    this.authServiceInstance.logoutRedirect({
      postLogoutRedirectUri: '/',
    })
    this.authServiceInstance = null
    this.currentUserSubject.next(null)
  }
}
