import { CommonModule } from '@angular/common'
import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core'
import { TabMenuModule } from 'primeng/tabmenu'
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { SortMeta } from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DialogModule } from 'primeng/dialog'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { PropertiesSelectorComponent } from '../properties/properties-selection.component'
import { LabelsService } from '../../shared/services/labels.service'
import { finalize } from 'rxjs'
import { ObjectUtils } from 'primeng/utils'
import { RadioButtonModule } from 'primeng/radiobutton'
import { ArraySpacingPipe } from '../../shared/pipes/array-spacing.pipe'

@Component({
  selector: 'app-labels-selector',
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ConfirmDialogModule,
    DropdownModule,
    PropertiesSelectorComponent,
    RadioButtonModule,
    ArraySpacingPipe,
  ],
  providers: [ConfirmationService],
  template: `
    <div class="pt-4 px-4 grid">
      <p-table
        #dt
        [columns]="selectedColumns"
        [value]="data"
        dataKey="LABEL_ID"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        (onRowSelect)="handleSelectionChanged()"
        (onRowUnselect)="handleSelectionChanged()"
        [rows]="50"
        selectionMode="single"
        [loading]="isLoading"
        [(selection)]="selectedLabel"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        sortMode="multiple"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
        [scrollable]="true"
        [multiSortMeta]="multiSortMeta"
        scrollHeight="53vh">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th></th>
            <th style="width: 5rem"></th>

            <th
              class="table-header text-small-strong"
              *ngFor="let col of columns"
              pSortableColumn="{{ col.field }}">
              <div style="display: flex; align-items: center">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                  class="mr-auto"></p-sortIcon>
              </div>
            </th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th style="min-width: 14vw;" *ngFor="let col of columns">
              @if (col.type) {

              <p-columnFilter
                [type]="col.type"
                matchMode="contains"
                [field]="col.field"></p-columnFilter>
              }
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-expanded="expanded">
          <tr class="text-small-roman" [pSelectableRow]="rowData">
            <td>
              <p-radioButton
                name="label"
                [value]="rowData"
                [(ngModel)]="selectedLabel"
                (click)="onRadioButtonClick($event, rowData)">
              </p-radioButton>
            </td>
            <td>
              <button
                type="button"
                pButton
                pRipple
                [pRowToggler]="rowData"
                class="p-button-text p-button-rounded p-button-plain"
                [icon]="
                  expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                "></button>
            </td>
            <td *ngFor="let col of columns">
              @if (col.field === 'properties'){
              <span [pRowToggler]="rowData"> Click to expand </span>
              } @else {
              {{ rowData[col.field] }}
              }
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-label>
          <tr>
            <td colspan="7">
              <div class="p-3">
                <p-table [value]="label.PROPERTIES" dataKey="name">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="PROPERTY_NAME">
                        Name <p-sortIcon field="PROPERTY_NAME"></p-sortIcon>
                      </th>
                      <th pSortableColumn="PROPERTY_DESCRIPTION">
                        Description
                        <p-sortIcon field="PROPERTY_DESCRIPTION"></p-sortIcon>
                      </th>
                      <th pSortableColumn="type">
                        Type <p-sortIcon field="date"></p-sortIcon>
                      </th>
                      <th pSortableColumn="default">
                        Options <p-sortIcon field="status"></p-sortIcon>
                      </th>
                      <th style="width: 4rem"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-property>
                    <tr>
                      <td>{{ property.PROPERTY_NAME }}</td>
                      <td>{{ property.PROPERTY_DESCRIPTION }}</td>
                      <td>{{ property.PROPERTY_DATA_OPERAND }}</td>
                      <td>
                        {{ property.PROPERTY_DATA_VALUES | arraySpacing }}
                      </td>
                      <!-- <td>{{ property.default }}</td> -->
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td style="border: none;" colspan="5">
              No results for your search terms.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [
    `
      .table-header {
        background-color: #cf9bff40;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
      }

      .text-small-strong {
        color: #8a00e5 !important;
      }

      td {
        border-right: 1px solid #969696;
      }

      .table-header-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    `,
  ],
})
export class LabelsSelectionComponent implements OnInit {
  items: MenuItem[] | undefined
  multiSortMeta: SortMeta[] = [{ field: 'ERDAT', order: -1 }]
  selectedLabel!: any
  isLoading = false

  cols: any[] = [
    { field: 'LABEL_NAME', header: 'Name', align: 'default', type: 'text' },
    {
      field: 'LABEL_DESCRIPTION',
      header: 'Description',
      align: 'default',
      type: 'text',
    },
    { field: 'properties', header: 'Properties', align: 'default' },
    // { field: 'type', header: 'Type', align: 'default' },
    // { field: 'options', header: 'Options', align: 'default' },
    // { field: 'default', header: 'Default values', align: 'default' },
  ]

  data!: any[]
  selectedColumns = this.cols
  @Output() onLabelSelected: EventEmitter<any[]> = new EventEmitter()

  private labelsService = inject(LabelsService)
  private messageService = inject(MessageService)

  ngOnInit(): void {
    this.fetchLabels()
  }

  fetchLabels() {
    this.isLoading = true
    this.labelsService
      .getLabels()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (labels) => {
          this.data = labels
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error fetching labels',
            life: 3000,
          })
        },
      })
  }

  resolveFieldData = (data: any, field: string) =>
    ObjectUtils.resolveFieldData(data, field)
      ? String(ObjectUtils.resolveFieldData(data, field))
      : ''

  handleSelectionChanged() {
    this.onLabelSelected.emit(this.selectedLabel)
  }

  onRadioButtonClick(event: any, rowData: any) {
    event.stopPropagation()
    this.selectedLabel = rowData
    this.handleSelectionChanged()
  }
}
