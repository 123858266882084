import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'humanize',
  standalone: true,
})
export class HumanizePipe implements PipeTransform {
  transform(value: string | number): string {
    if (typeof value === 'number') {
      return value.toString()
    }

    if (!value) return value

    return value
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\s{2,}/g, ' ') // Replace multiple spaces with a single space
      .trim() // Trim leading and trailing spaces
      .toLowerCase() // Convert the entire string to lower case
      .replace(/\b\w/g, (l) => l.toUpperCase()) // Capitalize the first letter of each word
  }
}
