import { CommonModule } from '@angular/common'
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core'
import { PropertiesService } from '../../shared/services/properties.service'
import { TabMenuModule } from 'primeng/tabmenu'
import { ConfirmationService, MessageService } from 'primeng/api'
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { SortMeta } from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect'
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms'
import { DialogModule } from 'primeng/dialog'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { finalize } from 'rxjs'

@Component({
  selector: 'app-properties-selector',
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ConfirmDialogModule,
    DropdownModule,
  ],
  providers: [ConfirmationService],
  template: `
    <div class="pt-4 px-4 grid">
      <p-table
        #dt
        [columns]="selectedColumns"
        [value]="properties"
        dataKey="PROPERTY_ID"
        [loading]="loading"
        (onRowSelect)="handleRowSelect()"
        (onRowUnselect)="handleRowSelect()"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="50"
        selectionMode="multiple"
        [scrollable]="true"
        [(selection)]="selectedProperties"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        sortMode="multiple"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
        [scrollable]="true"
        [multiSortMeta]="multiSortMeta"
        scrollHeight="53vh">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 2rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th
              class="table-header text-small-strong"
              *ngFor="let col of columns"
              pSortableColumn="{{ col.field }}">
              <div style="display: flex; align-items: center">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                  class="mr-auto"></p-sortIcon>
              </div>
            </th>
          </tr>
          <tr>
            <th></th>
            <th style="min-width: 14vw;" *ngFor="let col of cols">
              @if (col.type) {

              <p-columnFilter
                [type]="col.type"
                matchMode="contains"
                [field]="col.field"></p-columnFilter>
              }
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-expanded="expanded">
          <tr class="text-small-roman">
            <td>
              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td style="border: none;" colspan="5">
              No results for your search terms.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [
    `
      .table-header {
        background-color: #cf9bff40;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
      }

      .text-small-strong {
        color: #8a00e5 !important;
      }

      td {
        border-right: 1px solid #969696;
      }

      .table-header-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    `,
  ],
})
export class PropertiesSelectorComponent implements OnInit {
  user!: any
  multiSortMeta: SortMeta[] = [
    { field: 'PROPERTY_LAST_UPDATED_DATETIME', order: -1 },
  ]
  selectedProperty = null
  deleteReason = ''

  cols: any[] = [
    { field: 'PROPERTY_NAME', header: 'Name', align: 'default', type: 'text' },
    {
      field: 'PROPERTY_DESCRIPTION',
      header: 'Description',
      align: 'default',
      type: 'text',
    },
    // { field: 'PROPERTY_CREATED_DATETIME', header: 'Created Date', align: 'default' },
    // { field: 'PROPERTY_CREATED_USER_ID', header: 'Created By', align: 'default' },
    // { field: 'PROPERTY_LAST_UPDATED_DATETIME', header: 'Last Updated', align: 'default' },
    // { field: 'PROPERTY_LAST_UPDATED_USER_ID', header: 'Updated By', align: 'default' },
    {
      field: 'PROPERTY_DATA_OPERAND',
      header: 'Type',
      align: 'default',
      type: 'text',
    },
    // {
    //   field: 'PROPERTY_DATA_VALUES',
    //   header: 'Default Values',
    //   align: 'default',
    //   type: 'text',
    // },
    {
      field: 'PROPERTY_DATA_VALUES',
      header: 'Options',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_ID',
      header: 'Property ID',
      align: 'default',
      type: 'text',
    },
  ]

  selectedColumns = this.cols
  visible: boolean = false
  propertyForm!: FormGroup
  properties: any[] = []
  loading = false
  selectedProperties: any[] = []
  @Input() selection!: any
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter()

  private propertiesService = inject(PropertiesService)
  private messageService = inject(MessageService)
  private cdr = inject(ChangeDetectorRef)

  ngOnInit(): void {
    this.propertyForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      type: new FormControl(''),
      options: new FormControl(''),
      default: new FormControl(''),
      update: new FormControl(''),
    })

    if (this.selection) {
      this.selectedProperties = this.selection
    }

    this.fetchProperties()
  }

  handleRowSelect() {
    this.selectionChanged.emit(this.selectedProperties)
    this.sortTableBySelection()
  }

  fetchProperties() {
    this.loading = true
    this.propertiesService
      .getProperties()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (properties) => {
          this.properties = properties
          this.sortTableBySelection()
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error fetching properties',
            life: 3000,
          })
        },
      })
  }

  sortTableBySelection() {
    const selectedPropIds = this.selectedProperties.map(
      (prop: { PROPERTY_ID: any }) => prop.PROPERTY_ID
    )
    const selected = this.properties.filter((prop) =>
      selectedPropIds.includes(prop.PROPERTY_ID)
    )
    const unselected = this.properties.filter(
      (prop) => !selectedPropIds.includes(prop.PROPERTY_ID)
    )
    this.properties = [...selected, ...unselected]
    this.cdr.detectChanges()
  }
}
