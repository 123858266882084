export const environment = {
  production: false,
  envMode: 'dev',
  BASE_URL:
    'https://api-diagnostics-dev.data.siemens-energy.cloud/dev-create-lambda/v1',
  STARMS_URL:
    'https://api-diagnostics-dev.data.siemens-energy.cloud/dev-starms-proxy-lambda',
  msalConfig: {
    auth: {
      clientId: '0ab7d19b-d945-4f7b-9a41-548b6c33acd9', // PPE testing environment
      authority:
        'https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477', // PPE testing environment.
      redirectUri:
        'https://diagnostics-dev.data.siemens-energy.cloud/auth-callback',
      postLogoutRedirectUri: '/',
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  SUPPORT_APP_URL: 'https://support-center.edaa.siemens-energy.cloud',
}
