import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class StarmsService {
  url = `${environment.STARMS_URL}/starms_proxy`
  private http = inject(HttpClient)

  getTrains(): Observable<any> {
    return this.http.get(`${this.url}/list_trains`)
  }

  getTags(id: string): Observable<any> {
    return this.http.get(`${this.url}/list_tags?trainId=${id}`)
  }

  getTimeSeries(payload: any): Observable<any> {
    return this.http.post(`${this.url}/get_timeseries_data`, payload)
  }
}
