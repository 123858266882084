import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  url = `${environment.BASE_URL}/properties`
  private http = inject(HttpClient)

  getProperties(): Observable<any> {
    return this.http.get(`${this.url}/list`)
  }

  updateProperty(payload: any, id: any): Observable<any> {
    const queryParams =
      `property_id=${encodeURIComponent(payload.property_id)}&` +
      `property_data_operand=${encodeURIComponent(
        payload.property_data_operand
      )}&` +
      `user_id=${encodeURIComponent(payload.user_id)}&` +
      `property_name=${encodeURIComponent(payload.property_name)}&` +
      `property_description=${encodeURIComponent(payload.property_description)}`

    const fullUrl = `${this.url}/${payload.property_id}/update?${queryParams}`
    return this.http.put(fullUrl, {
      property_data_values:
        payload.property_data_values &&
        Array.isArray(payload.property_data_values) &&
        payload.property_data_values.length > 0
          ? payload.property_data_values
          : null,
    })
  }

  deleteProperty(id: string): Observable<any> {
    return this.http.delete(`${this.url}/${id}/delete`)
  }

  createProperty(payload: any): Observable<any> {
    const queryParams =
      `user_id=${encodeURIComponent(payload.user_id)}&` +
      `property_data_operand=${encodeURIComponent(
        payload.property_data_operand
      )}&` +
      `property_name=${encodeURIComponent(payload.property_name)}&` +
      `property_description=${encodeURIComponent(payload.property_description)}`

    const fullUrl = `${this.url}/create?${queryParams}`
    return this.http.post(fullUrl, {
      property_data_values:
        payload.property_data_values &&
        Array.isArray(payload.property_data_values) &&
        payload.property_data_values.length > 0
          ? payload.property_data_values
          : null,
    })
  }
}
