import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'arraySpacing', standalone: true })
export class ArraySpacingPipe implements PipeTransform {
  transform(value: any[]): string {
    if (!value || !Array.isArray(value)) {
      return ''
    }

    // Join the array elements with ', ' to add space after commas
    return value.join(', ')
  }
}
