import { Routes } from '@angular/router'
import { MsalGuard } from '@azure/msal-angular'
import { LayoutComponent } from './shared/components/ui/layout/layout.component'
import { LoginComponent } from './shared/components/login/login.component'
import { PropertiesComponent } from './features/properties/properties.component'
import { LabelsComponent } from './features/labels/labels.component'
import { SignaturesComponent } from './features/signatures/signatures.component'
import { ContactComponent } from './features/contact/contact.component'
import { FaqComponent } from './features/faq/faq.component'

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [MsalGuard],
      },
      //TODO: Make this lazy load
      {
        path: 'properties',
        component: PropertiesComponent,
        canActivate: [MsalGuard],
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'faq',
        component: FaqComponent,
        canActivate: [MsalGuard],
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'labels',
        component: LabelsComponent,
        canActivate: [MsalGuard],
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'signatures',
        component: SignaturesComponent,
        canActivate: [MsalGuard],
        data: {
          reuseComponent: true,
        },
      },
      {
        path: 'contact',
        component: ContactComponent,
        canActivate: [MsalGuard],
        data: {
          reuseComponent: true,
        },
      },
      //TODO: Check this
      { path: '', redirectTo: '/properties', pathMatch: 'full' },
      {
        path: '**',
        redirectTo: '',
        pathMatch: 'full',
        data: { loginStatus: false },
      },
    ],
  },
]
