import { CommonModule } from '@angular/common'
import { Component, OnInit, ViewChild, inject } from '@angular/core'
import { PropertiesService } from '../../shared/services/properties.service'
import { TabMenuModule } from 'primeng/tabmenu'
import { ConfirmationService, MessageService } from 'primeng/api'
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { SortMeta } from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect'
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms'
import { DialogModule } from 'primeng/dialog'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { ToastModule } from 'primeng/toast'
import { finalize } from 'rxjs/operators'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { ArraySpacingPipe } from '../../shared/pipes/array-spacing.pipe'
import { CustomLocalDatePipe } from '../../shared/pipes/custom-date-time.pipe'

@Component({
  selector: 'app-properties',
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ConfirmDialogModule,
    DropdownModule,
    ToastModule,
    ProgressSpinnerModule,
    ArraySpacingPipe,
    CustomLocalDatePipe,
  ],
  providers: [ConfirmationService],
  template: `
    <p-toast></p-toast>
    <div class="pt-4 px-4 grid">
      <p-button
        label="New property"
        (onClick)="showDialog()"
        class="rounded-lg pb-4"
        icon="pi pi-plus"></p-button>

      <p-table
        #dt
        [columns]="selectedColumns"
        [value]="data"
        dataKey="PROPERTY_ID"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="50"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        sortMode="multiple"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
        [scrollable]="true"
        [loading]="loading"
        [multiSortMeta]="multiSortMeta"
        scrollHeight="53vh">
        <ng-template pTemplate="caption">
          <div class="table-header-container" style="padding-bottom: 1rem">
            <h2>List of properties</h2>
            <p-multiSelect
              [options]="cols"
              [(ngModel)]="selectedColumns"
              optionLabel="header"
              selectedItemsLabel="{0} columns selected"
              [style]="{ 'min-width': '200px' }"
              placeholder="Choose Columns"></p-multiSelect>
            <div style="display: flex; gap: 1.5rem;">
              <p-button
                class="secondary-btn"
                [rounded]="true"
                label="Reset sorting"
                (click)="resetSort()"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.13843 6.75101L8.19576 5.80768L11.3338 2.66968L14.4718 5.80768L13.5291 6.75101L12.0004 5.22234V12.667H10.6671V5.22234L9.13843 6.75101Z"
                      fill="#8A00E5" />
                    <path
                      d="M3.99996 10.7779V3.33328H5.33396V10.7779L6.86196 9.24928L7.8053 10.1919L4.6673 13.3299L1.5293 10.1919L2.47196 9.24928L3.99996 10.7779Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
              <p-button
                class="secondary-btn"
                [rounded]="true"
                label="Reset filtering"
                (click)="dt.reset()"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.31998 9.33333L1.33398 2H14.6673L8.66732 9.33333V14H7.33398V9.33333H7.31998ZM11.854 3.33333H4.14398L7.95332 8H8.03598L11.854 3.33333Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
              <p-button
                class="secondary-btn"
                [rounded]="true"
                label="Download csv"
                (click)="dt.exportCSV()"
                [outlined]="true"
                severity="help">
                <ng-template pTemplate="icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.5727 6.15333L12.426 7.18L7.99935 10.8667L3.57268 7.18L4.42602 6.15333L7.33268 8.58V2H8.66602V8.58L11.5727 6.15333Z"
                      fill="#8A00E5" />
                    <path
                      d="M13.3327 14V12.6667H2.66602V14H13.3327Z"
                      fill="#8A00E5" />
                  </svg>
                </ng-template>
              </p-button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              class="table-header text-small-strong"
              *ngFor="let col of columns"
              pSortableColumn="{{ col.field }}">
              <div style="display: flex; align-items: center">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                  class="mr-auto"></p-sortIcon>
              </div>
            </th>
          </tr>
          <tr>
            <th style="min-width: 14vw;" *ngFor="let col of cols">
              @if (col.type) {

              <p-columnFilter
                [type]="col.type"
                matchMode="contains"
                [field]="col.field"></p-columnFilter>
              }
            </th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-rowData
          let-columns="columns"
          let-expanded="expanded">
          <tr class="text-small-roman">
            <td *ngFor="let col of columns">
              @if (col.field === 'actions') {
              <div class="flex gap-4">
                <p-button
                  icon="pi pi-pencil"
                  [rounded]="true"
                  (onClick)="showDialog(rowData)"
                  severity="help"
                  [outlined]="true"></p-button>
                <p-button
                  icon="pi pi-trash"
                  [rounded]="true"
                  (onClick)="confirm(rowData)"
                  severity="danger"
                  [outlined]="true"></p-button>
              </div>
              } @else if (col.field === 'PROPERTY_LAST_UPDATED_DATETIME') {
              {{ rowData[col.field] | customLocalDate : 'medium' }}
              } @else if (col.field === 'PROPERTY_DATA_VALUES') {
              {{ rowData[col.field] | arraySpacing }}
              } @else if(col.field === 'PROPERTY_LAST_UPDATED_USER_ID') {
              {{ rowData['PROPERTY_LAST_UPDATED_USER_LAST_NAME'] }},
              {{ rowData['PROPERTY_LAST_UPDATED_USER_GIVEN_NAME'] }}
              ( {{ rowData[col.field] }} ) } @else {
              {{ rowData[col.field] }}
              }
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td style="border: none;" colspan="5">
              No results for your search terms.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <p-dialog
      header="Header"
      [(visible)]="visible"
      [modal]="true"
      [style]="{ width: '50rem' }"
      [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
      <ng-template pTemplate="header">
        <div
          class="inline-flex align-items-center justify-content-center gap-2">
          <span class="font-bold white-space-nowrap">{{
            selectedProperty ? 'Edit property' : 'New property'
          }}</span>
        </div>
      </ng-template>
      <div>
        @if(loading) {
        <div class="flex items-center justify-center h-96">
          <p-progressSpinner></p-progressSpinner>
        </div>
        } @else {
        <form [formGroup]="propertyForm">
          <div class="flex flex-col gap-2 mb-4">
            <label for="name">Name</label>
            <input type="text" pInputText id="name" formControlName="name" />
            <small
              *ngIf="
                propertyForm.controls['name'].touched &&
                propertyForm.controls['name'].invalid
              "
              class="text-red-500">
              Name is required.
            </small>
          </div>
          <div class="flex flex-col gap-2 mb-4">
            <label for="description">Description</label>
            <textarea
              type="text"
              pInputTextarea
              rows="5"
              cols="30"
              id="description"
              formControlName="description"></textarea>
            <small
              *ngIf="
                propertyForm.controls['description'].touched &&
                propertyForm.controls['description'].invalid
              "
              class="text-red-500">
              Description is required.
            </small>
          </div>
          <div class="flex flex-col gap-2 mb-4">
            <label for="type">Type</label>
            <p-dropdown
              formControlName="type"
              optionValue="code"
              appendTo="body"
              [options]="types"
              optionLabel="name"
              placeholder="Select a type"></p-dropdown>
            <small
              *ngIf="
                propertyForm.controls['type'].touched &&
                propertyForm.controls['type'].invalid
              "
              class="text-red-500">
              Type is required.
            </small>
          </div>
          <div class="flex flex-col gap-2 mb-4" *ngIf="options.length > 0">
            <label for="options">Options</label>
            <div formArrayName="options" class="space-y-2">
              <div
                *ngFor="let option of options.controls; let i = index"
                class="flex items-center gap-2">
                <input
                  type="text"
                  pInputText
                  [formControlName]="i"
                  class="flex-1" />
                <button
                  type="button"
                  pButton
                  icon="pi pi-minus"
                  (click)="removeOptionControl(i)"
                  class="p-button-danger p-button-sm"
                  *ngIf="
                    propertyForm.controls['type'].value === 'CHOICE'
                  "></button>
              </div>
              <button
                type="button"
                pButton
                icon="pi pi-plus"
                (click)="addOptionControl()"
                class="p-button-success p-button-sm mt-2"
                *ngIf="
                  propertyForm.controls['type'].value === 'CHOICE'
                "></button>
            </div>
            <small *ngIf="options.invalid" class="text-red-500">
              Options are required.
            </small>
          </div>
          <div class="flex flex-col gap-2 mb-4" *ngIf="helperMessage">
            <small>{{ helperMessage }}</small>
          </div>
          <div
            class="flex flex-col gap-2 mb-4"
            *ngIf="propertyForm.controls['type'].value !== 'ANNOTATION'">
            <label for="default">Default values</label>
            <input
              type="text"
              pInputText
              id="default"
              formControlName="default" />
          </div>
          <div class="flex flex-col gap-2 mb-4" *ngIf="selectedProperty">
            <label for="update">Update reason</label>
            <input
              type="text"
              pInputText
              id="update"
              formControlName="update" />
          </div>
        </form>
        }
      </div>
      <ng-template pTemplate="footer">
        <p-button
          (click)="visible = false"
          [outlined]="true"
          [disabled]="loading"
          label="Cancel"
          pAutoFocus
          [autofocus]="true"></p-button>
        @if (selectedProperty) {
        <p-button
          icon="pi pi-check"
          (click)="saveProperty()"
          label="Save"
          [disabled]="loading"
          pAutoFocus
          [autofocus]="true"></p-button>
        } @else {
        <p-button
          icon="pi pi-check"
          (click)="createProperty()"
          label="Create"
          pAutoFocus
          [autofocus]="true"
          [disabled]="!propertyForm.valid || loading"></p-button>
        }
      </ng-template>
    </p-dialog>

    <p-confirmDialog #confirmDialog>
      <ng-template pTemplate="message" let-message>
        <div
          class="flex flex-col text-center align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
          <p>{{ message.message }}</p>
          <div>
            <p>
              Please type in the reason for <strong>DELETE</strong> in order to
              perform this action
            </p>
            <input
              type="text"
              class="mt-4"
              style="width: 100%;"
              pInputText
              (ngModelChange)="onReasonChange($event)"
              [(ngModel)]="deleteReason" />
          </div>
        </div>
      </ng-template>
    </p-confirmDialog>
  `,
  styles: [
    `
      .table-header {
        background-color: #cf9bff40;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
      }

      .text-small-strong {
        color: #8a00e5 !important;
      }

      td {
        border-right: 1px solid #969696;
      }

      .table-header-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    `,
  ],
})
export class PropertiesComponent implements OnInit {
  user!: any
  multiSortMeta: SortMeta[] = [
    { field: 'PROPERTY_LAST_UPDATED_DATETIME', order: -1 },
  ]
  selectedProperty: any = null
  deleteReason!: string
  currentUserId = localStorage.getItem('userId')
  // Additional properties for dynamic form controls
  dynamicOptions: any[] = []
  helperMessage: string = ''

  types = [
    { name: 'Annotation', code: 'ANNOTATION' },
    { name: 'Choice', code: 'CHOICE' },
    { name: 'Range', code: 'RANGE' },
    { name: 'Minimum', code: 'MINIMUM' },
    { name: 'Maximum', code: 'MAXIMUM' },
    { name: 'Boolean', code: 'BOOLEAN' },
  ]

  cols: any[] = [
    { field: 'PROPERTY_NAME', header: 'Name', align: 'default', type: 'text' },
    {
      field: 'PROPERTY_DESCRIPTION',
      header: 'Description',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_DATA_OPERAND',
      header: 'Type',
      align: 'default',
      type: 'text',
    },
    // {
    //   field: 'PROPERTY_DATA_VALUES',
    //   header: 'Default Values',
    //   align: 'default',
    //   type: 'text',
    // },
    {
      field: 'PROPERTY_DATA_VALUES',
      header: 'Options',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_LAST_UPDATED_DATETIME',
      header: 'Last updated time',
      align: 'default',
      type: 'date',
    },
    {
      field: 'PROPERTY_LAST_UPDATED_USER_ID',
      header: 'Last updated by',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_ID',
      header: 'Property ID',
      align: 'default',
      type: 'text',
    },
    { field: 'actions', header: 'Actions', align: 'default' },
  ]

  data!: any[]
  selectedColumns = this.cols
  visible: boolean = false
  propertyForm!: FormGroup
  loading: boolean = false
  @ViewChild('confirmDialog') confirmDialog!: any

  private propertiesService = inject(PropertiesService)
  private confirmationService = inject(ConfirmationService)
  private messageService = inject(MessageService)
  private fb = inject(FormBuilder)
  ngOnInit(): void {
    this.propertyForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      type: ['', Validators.required],
      options: this.fb.array([]),
      default: [{ value: '', disabled: true }],
      update: [''],
      id: [''],
    })

    this.propertyForm.get('type')?.valueChanges.subscribe((type) => {
      this.updateValidatorsBasedOnType(type)
    })

    this.fetchProperties()
  }

  fetchProperties() {
    this.loading = true
    this.propertiesService
      .getProperties()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: (properties) => {
          this.data = properties
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error fetching properties',
            life: 3000,
          })
        },
      })
  }

  resetSort() {
    this.multiSortMeta = [...this.multiSortMeta] // Reset to the initial sort state
  }

  onReasonChange(event: any) {
    this.disableAcceptButton(event)
  }

  disableAcceptButton(event: any) {
    let acceptButton = document.querySelector('.p-confirm-dialog-accept')
    if (acceptButton) {
      ;(acceptButton as HTMLButtonElement).disabled = event
        ? event === ''
        : true
    }
  }

  showDialog(data?: any) {
    this.visible = true
    if (data) {
      this.selectedProperty = data
      this.propertyForm.patchValue({
        name: data.PROPERTY_NAME,
        description: data.PROPERTY_DESCRIPTION,
        type: data.PROPERTY_DATA_OPERAND,
        options: data.PROPERTY_DATA_VALUES,
        default: data.default,
        id: data.PROPERTY_ID,
      })
    } else {
      this.propertyForm.reset()
      this.selectedProperty = null
    }
  }

  confirm(data: { PROPERTY_ID: string }) {
    this.confirmationService.confirm({
      header: 'Delete property',
      message: 'Are you sure that you want to proceed?',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-outlined p-button-sm',
      acceptButtonStyleClass: 'p-button-sm',
      accept: () => {
        this.propertiesService.deleteProperty(data.PROPERTY_ID).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Confirmed',
              detail: 'Property was deleted.',
              life: 3000,
            })
            this.fetchProperties()
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Property is being used in a label',
              life: 3000,
            })
          },
        })
        this.deleteReason = ''
      },
      reject: () => {
        this.deleteReason = ''
      },
    })
    setTimeout(() => {
      this.disableAcceptButton(null)
    }, 20)
  }

  saveProperty() {
    this.loading = true
    let propertyDataValues = this.propertyForm.value.options

    if (
      this.propertyForm.value.type === 'RANGE' ||
      this.propertyForm.value.type === 'MINIMUM' ||
      this.propertyForm.value.type === 'MAXIMUM'
    ) {
      propertyDataValues = propertyDataValues.map((value: string) =>
        Number(value)
      )
    }

    const payload: any = {
      property_id: this.selectedProperty.PROPERTY_ID,
      user_id: this.currentUserId,
      property_name: this.propertyForm.value.name,
      property_description: this.propertyForm.value.description,
      property_data_operand: this.propertyForm.value.type,
    }

    if (this.propertyForm.value.type !== 'ANNOTATION') {
      payload.property_data_values = propertyDataValues
    }

    this.propertiesService
      .updateProperty(payload, this.propertyForm.value.id)
      .subscribe({
        next: () => {
          this.visible = false
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Property updated',
            life: 3000,
          })
          this.fetchProperties()
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error updating the property',
            life: 3000,
          })
          this.loading = false
        },
      })
  }

  createProperty() {
    this.loading = true
    let propertyDataValues = this.propertyForm.value.options

    if (
      this.propertyForm.value.type === 'RANGE' ||
      this.propertyForm.value.type === 'MINIMUM' ||
      this.propertyForm.value.type === 'MAXIMUM'
    ) {
      propertyDataValues = propertyDataValues.map((value: string) =>
        Number(value)
      )
    }

    const payload: any = {
      property_data_operand: this.propertyForm.value.type,
      user_id: this.currentUserId,
      property_name: this.propertyForm.value.name,
      property_description: this.propertyForm.value.description,
    }

    if (this.propertyForm.value.type !== 'ANNOTATION') {
      payload.property_data_values = propertyDataValues
    }

    this.propertiesService.createProperty(payload).subscribe({
      next: () => {
        this.visible = false
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Property created',
          life: 3000,
        })
        this.fetchProperties()
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error creating the property',
          life: 3000,
        })
        this.loading = false
      },
    })
  }

  get options(): FormArray {
    return this.propertyForm.get('options') as FormArray
  }

  updateValidatorsBasedOnType(type: string) {
    // Clear existing form array controls
    this.options.clear()
    const defaultControl = this.propertyForm.get('default')

    if (!defaultControl) return

    // Reset helper message
    this.helperMessage = ''

    switch (type) {
      case 'ANNOTATION':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage = 'Annotations cannot have predefined values.'
        break

      case 'CHOICE':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage = 'Choice requires at least two list elements.'
        this.addOptionControls(
          this.selectedProperty?.PROPERTY_DATA_VALUES.length || 2
        )
        break

      case 'RANGE':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage =
          'Range requires a list of two elements (lower and upper bound).'
        this.addOptionControls(2)
        break

      case 'MINIMUM':
      case 'MAXIMUM':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage = `${
          type.charAt(0).toUpperCase() + type.slice(1)
        } requires a list of only one element.`
        this.addOptionControls(1)
        break

      case 'BOOLEAN':
        defaultControl.clearValidators()
        defaultControl.setValue('')
        this.helperMessage =
          'There is no need to provide values for BOOLEAN operand.'
        break

      default:
        defaultControl.clearValidators()
        defaultControl.setValue('')
        break
    }

    defaultControl.updateValueAndValidity()
  }

  rangeValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value
    if (!value || value.length !== 2) {
      return { rangeLength: true }
    }
    const [min, max] = value
    if (isNaN(min) || isNaN(max) || parseFloat(min) > parseFloat(max)) {
      return { rangeOrder: true }
    }
    return null
  }

  numberValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value
    if (!value || value.length !== 1) {
      return { singleValue: true }
    }
    if (isNaN(value[0])) {
      return { notANumber: true }
    }
    return null
  }

  addOptionControl() {
    this.options.push(this.fb.control('', Validators.required))
  }

  addOptionControls(count: number) {
    for (let i = 0; i < count; i++) {
      this.options.push(this.fb.control('', Validators.required))
    }
  }

  removeOptionControl(index: number) {
    if (this.options.length > 2) {
      // Ensure at least two options are present
      this.options.removeAt(index)
    }
  }
}
