import { CommonModule } from '@angular/common'
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core'
import Plotly from 'plotly.js-dist'
import { Data, Layout, Config } from 'plotly.js'

@Component({
  selector: 'app-timeseries',
  template: ` <div #chart></div> `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
        height: 100%;
      }
      div {
        width: 100%;
        height: 100%;
      }
    `,
  ],
  standalone: true,
  imports: [CommonModule],
})
export class TimeSeriesChart implements OnInit, AfterViewInit {
  @Input() chartData: any
  @Output() rangeSelected: EventEmitter<any> = new EventEmitter()
  @ViewChild('chart') chartDiv!: ElementRef

  ngOnInit() {}

  ngAfterViewInit() {
    this.initChart()
  }

  initChart() {
    if (!this.chartData || this.chartData.length === 0) {
      return
    }

    // Create traces efficiently
    const traces: Partial<Data>[] = this.chartData.map((series: any) => ({
      x: series.data.map((point: any) => new Date(point[0])),
      y: series.data.map((point: any) => point[1]),
      type: 'scatter',
      mode: 'lines',
      name: series.name,
      fill: 'tozeroy',
    }))

    // Optimize layout configuration
    const layout: Partial<Layout> = {
      title: 'Time Series Data',
      xaxis: { title: 'Date', type: 'date' },
      yaxis: { title: 'Value' },
      showlegend: true,
      dragmode: 'select',
      selectdirection: 'h',
    }

    // Configure Plotly for performance
    const config: Partial<Config> = {
      responsive: true,
      modeBarButtonsToRemove: ['toImage'],
      displayModeBar: true,
      displaylogo: false,
      modeBarButtons: [
        [
          'zoom2d',
          'pan2d',
          'select2d',
          'zoomIn2d',
          'zoomOut2d',
          'autoScale2d',
          'resetScale2d',
        ],
      ],
      staticPlot: false,
    }

    Plotly.newPlot(this.chartDiv.nativeElement, traces, layout, config)

    let debounceTimeout: any
    this.chartDiv.nativeElement.on('plotly_selected', (eventData: any) => {
      if (eventData) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          const xRange = eventData.range.x
          const minDate = new Date(xRange[0])
          const maxDate = new Date(xRange[1])

          console.log(
            `Selected range: ${minDate.toISOString()} to ${maxDate.toISOString()}`
          )
          this.rangeSelected.emit({
            min: minDate,
            max: maxDate,
          })
        }, 300)
      }
    })
  }
}
