import { CommonModule } from '@angular/common'
import { Component, OnInit, inject } from '@angular/core'
import { AzureService } from '../auth/azure.service'
import { PropertiesService } from '../../shared/services/properties.service'
import { TabMenuModule } from 'primeng/tabmenu'
import { MenuItem } from 'primeng/api'
import { MsalService } from '@azure/msal-angular'

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, TabMenuModule],
  template: `
    <!-- Logged in user: {{user?.displayName}} <br> <a href="" (click)="logout()">Logout</a> -->
    <div class="py-2 px-4 flex gap-4 items-center">
      <img
        alt="SE Logo"
        class="logo"
        width="108"
        height="42"
        src="/assets/images/Siemens_Energy_logo_BG_white.png" />
      <h2>Labelling App</h2>
    </div>
    <div class="mt-4 mx-4">
      <p-tabMenu [model]="items"></p-tabMenu>
    </div>
  `,
  styles: [``],
})
export class HeaderComponent implements OnInit {
  user!: any
  items: MenuItem[] | undefined

  private azureService = inject(AzureService)
  private msalService = inject(MsalService)
  private propertiesService = inject(PropertiesService)

  ngOnInit(): void {
    this.azureService.getUserInfo().subscribe({
      next: () => {
        this.azureService.getCurrentUser().subscribe({
          next: (user: any) => {
            console.log(user)
            this.user = user
          },
        })
      },
    })
    const account = this.msalService.instance.getActiveAccount()
    if (account && account.idTokenClaims) {
      localStorage.setItem('userId', account?.idTokenClaims['SCGID'] as string)
    }
    this.items = [
      {
        label: 'Signatures',
        icon: 'pi pi-fw pi-file-edit',
        routerLink: 'signatures',
      },
      { label: 'Labels', icon: 'pi pi-fw pi-tag', routerLink: 'labels' },
      {
        label: 'Properties',
        icon: 'pi pi-fw pi-id-card',
        routerLink: 'properties',
      },
      { label: 'FAQ', icon: 'pi pi-fw pi-info-circle', routerLink: 'faq', },
      {
        label: 'Contact us',
        icon: 'pi pi-fw pi-comment',
        routerLink: 'contact',
      },
    ]
  }

  logout() {
    this.azureService.logout()
  }
}
