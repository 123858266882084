import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core'
import { TabMenuModule } from 'primeng/tabmenu'
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button'
import { TooltipModule } from 'primeng/tooltip'
import { InputTextModule } from 'primeng/inputtext'
import { SortMeta } from 'primeng/api'
import { MultiSelectModule } from 'primeng/multiselect'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DialogModule } from 'primeng/dialog'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { StarmsService } from '../../shared/services/starms.service'
import { finalize } from 'rxjs'
import { OverlayPanelModule } from 'primeng/overlaypanel'

@Component({
  selector: 'app-tags-selector',
  standalone: true,
  imports: [
    CommonModule,
    TabMenuModule,
    MultiSelectModule,
    FormsModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ConfirmDialogModule,
    DropdownModule,
    OverlayPanelModule,
  ],
  providers: [ConfirmationService],
  template: `
    <div class="pt-4 px-4 grid">
      <p-table
        #dt
        [columns]="selectedColumns"
        [value]="sortedData"
        [loading]="isLoading"
        dataKey="tagId"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="50"
        [scrollable]="true"
        selectionMode="multiple"
        (onRowSelect)="tagSelected()"
        (onRowUnselect)="tagSelected()"
        [(selection)]="selectedTags"
        styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        sortMode="multiple"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
        [multiSortMeta]="multiSortMeta"
        scrollHeight="53vh">
        <ng-template pTemplate="caption">
          <div class="flex items-center gap-6 h-12">
            <span class="p-mr-2"
              >Selected items: {{ selectedTags.length }}</span
            >
            @if(selectedTags.length >= 1) {
            <button
              pButton
              class="p-button-outlined"
              size="small"
              icon="pi pi-info-circle"
              (click)="op.toggle($event)"></button>
            }
          </div>
          <p-overlayPanel
            #op
            [style]="{ width: '50vw' }"
            [showCloseIcon]="true">
            <ng-template pTemplate="content">
              <p-table
                [value]="selectedTags"
                [columns]="selectedColumns"
                [rows]="5">
                <ng-template pTemplate="header" let-columns>
                  <th
                    class="table-header text-small-strong"
                    *ngFor="let col of columns">
                    <div style="display: flex; align-items: center">
                      {{ col.header }}
                    </div>
                  </th>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td *ngFor="let col of columns">
                      {{ rowData[col.field] }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </ng-template>
          </p-overlayPanel>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th
              class="table-header text-small-strong"
              *ngFor="let col of columns"
              pSortableColumn="{{ col.field }}">
              <div style="display: flex; align-items: center">
                {{ col.header }}
                <p-sortIcon
                  [field]="col.field"
                  ariaLabel="Activate to sort"
                  ariaLabelDesc="Activate to sort in descending order"
                  ariaLabelAsc="Activate to sort in ascending order"
                  class="mr-auto"></p-sortIcon>
              </div>
            </th>
          </tr>
          <tr>
            <th></th>
            <th style="min-width: 14vw;" *ngFor="let col of cols">
              <p-columnFilter
                [type]="col.type"
                matchMode="contains"
                [field]="col.field"></p-columnFilter>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <td>
              <p-tableCheckbox
                (click)="onRadioButtonClick($event, rowData)"
                [value]="rowData"></p-tableCheckbox>
            </td>
            <td *ngFor="let col of columns">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td style="border: none;" colspan="5">
              No results for your search terms.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  `,
  styles: [
    `
      .table-header {
        background-color: #cf9bff40;
        padding: 0.5rem 0.75rem;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        white-space: nowrap;
      }

      .text-small-strong {
        color: #8a00e5 !important;
      }

      td {
        border-right: 1px solid #969696;
      }

      .table-header-container {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      }
    `,
  ],
})
export class TagsSelectorComponent implements OnInit {
  @Input() trainId!: string
  @Output() onTagSelected: EventEmitter<any[]> = new EventEmitter()

  user!: any
  items: MenuItem[] | undefined
  multiSortMeta: SortMeta[] = [{ field: 'ERDAT', order: -1 }]
  selectedTags: any[] = []
  deleteReason = ''
  activeIndex: number = 0

  cols: any[] = [
    { field: 'tagId', header: 'ID', align: 'default', type: 'text' },
    { field: 'tagAlias', header: 'Alias', align: 'default', type: 'text' },
    {
      field: 'description',
      header: 'Description',
      align: 'default',
      type: 'text',
    },
    {
      field: 'customerTagAlias',
      header: 'Customer Tag Alias',
      align: 'default',
      type: 'text',
    },
  ]

  data!: any[]
  selectedColumns = this.cols
  visible: boolean = false
  isLoading = false
  sortedData!: any[]

  private messageService = inject(MessageService)
  private starmsService = inject(StarmsService)

  ngOnInit(): void {
    this.getTags()
  }

  getTags() {
    this.isLoading = true
    this.starmsService
      .getTags(this.trainId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (tags) => {
          this.data = tags
          this.sortedData = [...tags] // Initialize sortedData
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'There was an error fetching tags',
            life: 3000,
          })
        },
      })
  }

  tagSelected() {
    this.onTagSelected.emit(this.selectedTags)
    this.sortBySelected()
  }

  sortBySelected() {
    this.sortedData = [...this.data].sort((a, b) => {
      const aSelected = this.selectedTags.includes(a)
      const bSelected = this.selectedTags.includes(b)
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1
    })
  }

  onRadioButtonClick(event: any, rowData: any) {
    event.stopPropagation()
  }
}
