import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  HostListener,
  inject,
} from '@angular/core'
import { SignaturesService } from '../../shared/services/signatures.service'
import { CommonModule } from '@angular/common'
import { DialogModule } from 'primeng/dialog'
import { TimeSeriesChart } from '../../shared/components/timeseries-chart/timeseries-chart.component'
import { StarmsService } from '../../shared/services/starms.service'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { ButtonModule } from 'primeng/button'
import { AccordionModule } from 'primeng/accordion'
import { HumanizePipe } from '../../shared/pipes/humanize.pipe'
import { TableModule } from 'primeng/table'
import { CustomLocalDatePipe } from '../../shared/pipes/custom-date-time.pipe'

interface KeyValue {
  key: string
  value: any
}

interface KeyValues {
  eventInfo: KeyValue[]
  assetInfo: KeyValue[]
  labelInfo: KeyValue[]
}

@Component({
  selector: 'app-signature-detail',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    TimeSeriesChart,
    ProgressSpinnerModule,
    ButtonModule,
    AccordionModule,
    HumanizePipe,
    TableModule,
    CustomLocalDatePipe,
  ],
  template: `
    <p-dialog
      *ngIf="signatures"
      [(visible)]="visible"
      (onHide)="onHide.emit()"
      [modal]="true"
      [style]="{ height: '90vh', width: '85vw' }"
      [breakpoints]="{ '1199px': '85vw', '575px': '90vw' }">
      <ng-template pTemplate="header">
        <div class="header-container">
          <button
            (click)="prevSignature()"
            [disabled]="currentIndex === 0"
            pButton
            icon="pi pi-arrow-left"></button>
          <span class="title">{{ currentSignature?.LABEL_NAME }}</span>
          <span class="count"
            >{{ currentIndex + 1 }} / {{ signatures.length }}</span
          >
          <button
            (click)="nextSignature()"
            [disabled]="currentIndex === signatures.length - 1"
            pButton
            icon="pi pi-arrow-right"></button>
        </div>
      </ng-template>
      <div class="grid">
        <div class="col-6 h-[40vh]">
          <ng-container *ngIf="chartData; else loading">
            <app-timeseries [chartData]="chartData"></app-timeseries>
          </ng-container>
          <ng-template #loading>
            <div class="flex items-center justify-center h-96">
              <p-progressSpinner></p-progressSpinner>
            </div>
          </ng-template>
        </div>
        <div style="margin: 0rem 1rem; max-width: 45%;">
          <p-accordion>
            <p-accordionTab header="Event Info">
              <ul class="ml-6">
                <li>
                  <strong>Event Start Date:</strong>
                  {{
                    currentSignature?.EVENT_START_DATE
                      | customLocalDate : 'medium'
                  }}
                </li>
                <li>
                  <strong>Event End Date:</strong>
                  {{
                    currentSignature?.EVENT_END_DATE
                      | customLocalDate : 'medium'
                  }}
                </li>
                <li *ngFor="let item of keyValues.eventInfo">
                  <strong>{{ item.key | humanize }}:</strong> {{ item.value }}
                </li>
              </ul>
            </p-accordionTab>
            <p-accordionTab header="Asset Info">
              <ul class="ml-6">
                <li *ngFor="let item of keyValues.assetInfo">
                  <strong>{{ item.key | humanize }}:</strong> {{ item.value }}
                </li>
              </ul>
            </p-accordionTab>
            <p-accordionTab header="Label Info">
              <ul class="ml-6">
                <li *ngFor="let item of keyValues.labelInfo">
                  <strong>{{ item.key | humanize }}:</strong>
                  @if(item.key === 'LABEL_LAST_UPDATED_DATETIME' || item.key ===
                  'LABEL_CREATED_DATETIME') {
                  {{ item.value | customLocalDate : 'medium' }}
                  } @else {
                  {{ item.value | humanize }}
                  }
                </li>
              </ul>
            </p-accordionTab>
          </p-accordion>
        </div>
        <div>
          <p-table
            #dt
            [columns]="cols"
            [value]="currentSignature.PROPERTIES"
            dataKey="PROPERTY_ID"
            [tableStyle]="{ 'min-width': '50rem' }"
            [rows]="50"
            selectionMode="multiple"
            [scrollable]="true"
            styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"
            sortMode="multiple"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[50, 100, 150, { showAll: 'All' }]"
            [scrollable]="true"
            scrollHeight="53vh">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th
                  class="table-header text-small-strong"
                  *ngFor="let col of columns"
                  pSortableColumn="{{ col.field }}">
                  <div style="display: flex; align-items: center">
                    {{ col.header }}
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template
              pTemplate="body"
              let-rowData
              let-columns="columns"
              let-expanded="expanded">
              <tr class="text-small-roman">
                <td *ngFor="let col of columns">
                  {{ rowData[col.field] }}
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td style="border: none;" colspan="5">
                  No properties for this signature.
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-dialog>
  `,
  styles: [
    `
      .header-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        position: relative;
      }
      .title {
        font-weight: bold;
        white-space: nowrap;
        flex: 1;
        text-align: center;
      }
      .count {
        white-space: nowrap;
      }
      .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .col-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
      ul {
        padding: 0;
        list-style: none;
      }
      li {
        margin-bottom: 0.5rem;
      }
      button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }
    `,
  ],
})
export class SignatureDetailComponent implements OnInit, OnChanges {
  @Input() visible: boolean = false
  @Input() signatures: any[] = []
  @Input() currentIndex: number = 0
  @Output() onHide = new EventEmitter<void>()

  currentSignature: any
  chartData: any
  keyValues: KeyValues = {
    eventInfo: [],
    assetInfo: [],
    labelInfo: [],
  }
  tags!: any

  cols: any[] = [
    { field: 'PROPERTY_NAME', header: 'Name', align: 'default', type: 'text' },
    {
      field: 'PROPERTY_DESCRIPTION',
      header: 'Description',
      align: 'default',
      type: 'text',
    },
    // { field: 'PROPERTY_CREATED_DATETIME', header: 'Created Date', align: 'default' },
    // { field: 'PROPERTY_CREATED_USER_ID', header: 'Created By', align: 'default' },
    // { field: 'PROPERTY_LAST_UPDATED_DATETIME', header: 'Last Updated', align: 'default' },
    // { field: 'PROPERTY_LAST_UPDATED_USER_ID', header: 'Updated By', align: 'default' },
    {
      field: 'PROPERTY_DATA_OPERAND',
      header: 'Type',
      align: 'default',
      type: 'text',
    },
    // {
    //   field: 'PROPERTY_DATA_VALUES',
    //   header: 'Default Values',
    //   align: 'default',
    //   type: 'text',
    // },
    {
      field: 'PROPERTY_DATA_VALUES',
      header: 'Options',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_ID',
      header: 'Property ID',
      align: 'default',
      type: 'text',
    },
    {
      field: 'PROPERTY_ASSIGNED_VALUE',
      header: 'Assigned value',
      align: 'default',
      type: 'text',
    },
  ]

  private signaturesService = inject(SignaturesService)
  private starmsService = inject(StarmsService)

  ngOnInit() {
    this.loadSignatureDetails()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentIndex'] || changes['signatures']) {
      this.loadSignatureDetails()
    }
  }

  loadSignatureDetails() {
    if (this.signatures && this.signatures.length > 0) {
      this.currentSignature = this.signatures[this.currentIndex]
      this.tags = [...this.currentSignature.ASSET?.tags]
      this.chartData = null
      this.getTimeSeries(
        this.currentSignature.EVENT_START_DATE,
        this.currentSignature.EVENT_END_DATE
      )
      this.loadChartData()
      this.loadKeyValues()
    }
  }

  loadChartData() {
    const startDate = new Date(this.currentSignature.EVENT_START_DATE).getTime()
    const endDate = new Date(this.currentSignature.EVENT_END_DATE).getTime()
  }

  loadKeyValues() {
    const eventInfoKeys = [
      'EVENT_TRX_ID',
      'EVENT_ID',
      'EVENT_LABEL_ID',
      'EVENT_ASSET_ID',
      'EVENT_CREATED_USER_ID',
      'EVENT_LAST_UPDATED_USER_ID',
      'EVENT_CREATED_DATETIME',
    ]

    const assetInfoKeys = [
      'applicationMode',
      'assemblyId',
      'assignedPL',
      'country',
      'drivenUnitTypes',
      'driverTypes',
      'drivers',
      'region',
      'siteId',
      'trainId',
      'trainName',
      'trainNo',
    ]

    const labelInfoKeys = [
      'LABEL_ID',
      'LABEL_NAME',
      'LABEL_DESCRIPTION',
      'LABEL_LOOKBACK_PERIOD',
      'LABEL_CREATED_USER_ID',
      'LABEL_CREATED_DATETIME',
      'LABEL_LAST_UPDATED_USER_ID',
      'LABEL_LAST_UPDATED_DATETIME',
    ]

    this.keyValues = {
      eventInfo: [],
      assetInfo: [],
      labelInfo: [],
    }

    Object.keys(this.currentSignature).forEach((key) => {
      if (eventInfoKeys.includes(key)) {
        this.keyValues.eventInfo.push({
          key: key,
          value: this.currentSignature[key],
        })
      } else if (labelInfoKeys.includes(key)) {
        this.keyValues.labelInfo.push({
          key: key,
          value: this.currentSignature[key],
        })
      }
    })

    if (this.currentSignature.ASSET) {
      Object.keys(this.currentSignature.ASSET).forEach((key) => {
        if (assetInfoKeys.includes(key)) {
          this.keyValues.assetInfo.push({
            key: key,
            value: this.currentSignature.ASSET[key],
          })
        }
      })

      if (this.currentSignature.ASSET.label) {
        this.currentSignature.ASSET.label.values.forEach(
          (value: any, index: number) => {
            const labelKey = `Label Value ${index + 1}`
            this.keyValues.labelInfo.push({
              key: labelKey,
              value: value !== null ? value : 'N/A',
            })
          }
        )
      }

      if (this.currentSignature.ASSET.tags) {
        this.currentSignature.ASSET.tags.forEach(
          (tag: { tagId: any; tagAlias: any }) => {
            this.keyValues.assetInfo.push({
              key: `Tag ${tag.tagId}`,
              value: tag.tagAlias,
            })
          }
        )
      }
    }
  }

  isArray(value: any): boolean {
    return Array.isArray(value)
  }

  nextSignature() {
    if (this.currentIndex < this.signatures.length - 1) {
      this.currentIndex++
      this.loadSignatureDetails()
    }
  }

  prevSignature() {
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.loadSignatureDetails()
    }
  }

  @HostListener('document:keydown.arrowright', ['$event'])
  handleRightArrow(event: KeyboardEvent) {
    this.nextSignature()
  }

  @HostListener('document:keydown.arrowleft', ['$event'])
  handleLeftArrow(event: KeyboardEvent) {
    this.prevSignature()
  }

  getTimeSeries(start: any, end: any) {
    const payload = {
      ts_from: start,
      ts_to: end,
      sample_rate: 60,
      tagIds: this.tags.map((tag: { tagId: any }) => tag.tagId),
    }

    this.starmsService.getTimeSeries(payload).subscribe({
      next: (res) => {
        this.chartData = Object.keys(res).map((key) => {
          const tagAlias =
            this.tags.find((tag: { tagId: number }) => tag.tagId === +key)
              ?.tagAlias || key
          return {
            name: tagAlias,
            data: res[key].map(
              (point: { datetime: string | number | Date; value: any }) => [
                new Date(point.datetime).getTime(),
                point.value !== null ? point.value : null,
              ]
            ),
          }
        })
      },
      error: () => {},
    })
  }
}
